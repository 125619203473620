import { NavLink } from "react-router-dom"
import Button from "../../components/Button/Button"

const Error = () => {
  return (
    <>
      <div
      style={{
        height: "75vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      >
        <div>
          <h1 style={{ textAlign: "center" }}>SolarVP Account Error</h1>
          <h2 style={{ textAlign: "center", marginTop: "2rem" }}>You may not have permission to view this page</h2>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
            <NavLink to="/">
              <Button>
                Back to main page
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error;
