import Icons from "../Icons/Icons";

export const ButtonClose = ({
  onClickEvent
}) => {
  return (
    <button
      onClick={onClickEvent}
      type="button"
      className="button-close"
    >
      <Icons.Close/>
    </button>
  );
};

export default ButtonClose;