import Icons from "../../components/Icons/Icons";

const TableNoResults = ({ icon, text }) => {
  return (
    <div className="datatable__noresults">
      <div className="datatable__noresults__icon">
        {icon || <Icons.SadPin />}
      </div>
      <h5>{text}</h5>
    </div>
  );
};

export default TableNoResults;
