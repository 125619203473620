// Imports
import * as THREE from "three";

const Edge = ({ data }) => {
  return (
    <>
      <mesh
        geometry={data}
        castShadow
        receiveShadow
      >
        <EdgeTexture layer={data?.userData.layer} />
      </mesh>
    </>
  );
};

const EdgeTexture = ({ layer }) => {

  const fillColor = {
    Tarejgerinc:      "#FF4136",
    Elgerinc:         "#FF851B",
    Oromszegely:      "#00A3FF",
    Eresz:            "#FFDC00",
    Vapa:             "#2ECC40",
    Manzardtores:     "#0074D9",
    Hajlasszogtores:  "#B10DC9",
    Falszegely:       "#85144B",
    Egyebszegely:     "#B6B6B6",
  };

  return (
    <meshBasicMaterial
      side={THREE.DoubleSide}
      color={fillColor[layer]}
    />
  );
};

export default Edge;