import { proxy } from "valtio";

// Default states
const SnapLogin = proxy({
  loggedIn: false,
  loginEmail: "",
  loginPassword: "",
  rememberMe: false,
  forgottenEmail: "",
});

// Set user logged in
export const SetUserLoggedIn = (value) => (SnapLogin.loggedIn = value);

// Set user login email
export const SetUserEmail = (email) => (SnapLogin.loginEmail = email);

// Set user login password
export const SetUserPassword = (password) =>
  (SnapLogin.loginPassword = password);

// Remember user login data if checkbox is ticked
export const SetRememberMe = (value) => {
  SnapLogin.rememberMe = value;
};

// Set user forgotten email
export const SetUserForgottenEmail = (email) =>
  (SnapLogin.forgottenEmail = email);

export default SnapLogin;
