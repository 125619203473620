// Imports
import { urls } from "../urls";
import { CheckError } from "../../utils/api/checkError";

// Create new domain
const createDomain = async (body, message, modalOpen, setFormData) => {
  await fetch(urls.domains, {
    method: "POST",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
      'Content-Type': 'application/json'
    }, 
    body: JSON.stringify({
        'domain_name': body.domain_name,
        'company_name': body.company_name,
        'company_postal': body.company_postal,
        'company_city': body.company_city,
        'company_address': body.company_address,
        'company_vat': body.company_vat,
        'on_trial': body.on_trial,
      }),
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      // Send success message to user
      message(["success", "Domain sikeresen létrehozva!"]);
      // Close modal
      modalOpen(false)
      // Clear form fields after success request 
      setFormData({
        domain_name: "",
        company_name: "",
        company_postal: "",
        company_city: "",
        company_address: "",
        company_vat: "",
        on_trial: false,
      })
    })
    .catch((error) => {
      console.log(error);
    });
};

export default createDomain;
