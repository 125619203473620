// Imports
import { SetScopedUser } from "../../data/store/ScopedUser";
import { urls } from "../urls";
import { CheckError } from "../../utils/api/checkError";

// Get selected user 
const getScopedUser = async (body, message) => {
  await fetch(urls.userByID + body.id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    }
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
        SetScopedUser(actualData)
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getScopedUser;
