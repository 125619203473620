// Base URL
const baseURL = "https://api.solarvp.hu/api/v1/";
const baseURLV2 = "https://api.solarvp.hu/api/v2/";

// Routes
const superAdmin = `${baseURL}superadmin/`;
const users = `${baseURL}users/`;
const domains = `${baseURL}domains/`;
const requests = `${baseURL}requests/`;

// Used urls in API calls
export const urls = {
  health: `https://api.solarvp.hu/api/v1/public/health`,
  
  // SuperAdmin account management
  login: `${superAdmin}login`,
  profile: `${superAdmin}me`,
  newPass: `${superAdmin}profile/password`,

  // User management
  users: `${users}`,  // GET all users and POST new user
  userByID: `${users}user/`,  // +{user_id} - GET, PATCH, Activate/Deactivate
  promoteUser: `${users}`,  // +{user_id} - PATCH for converting user to admin
  convertUser: `${baseURLV2}user/`,  // Convert user to admin or convert admin to user V2
 
  // Domain management
  domains: `${domains}`,  // GET all domains and POST new domain
  domainByID: `${domains}`,  // +{domain_id} - GET, PATCH specific domain

  // Request management
  requests: `${superAdmin}requests/`,  // GET endpoint for all requests
  requestsFileUpload: `${superAdmin}requests/files/`,  // POST upload files for specific request
  newRequest: `${requests}`,  // POST new request
  requestByID: `${users}request/`,  // +{request_id} - GET, PATCH specific request
  requestByDomain: `${users}request/domain/`,  // +{domain_id} - GET all request by specific domain

  // File streams
  fileByID: `${users}request/file/`,  // +{request_id} then /zip or /pcd
};