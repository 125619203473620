import { useContext, useEffect, useState } from "react";
import Badge from "../Badge/Badge";
import { ModalContext } from "../Router/Router";
import Carousel from "../Carousel/Carousel";
import CarouselSmall from "../Carousel/CarouselSmall";
import ButtonClose from "../Button/ButtonClose";
// Valtio and store imports
import { useSnapshot } from "valtio";
import SnapScopedRequest, {
  ClearScopedRequest,
  setToolbar,
} from "../../data/store/ScopedRequest";

// Toast import
import { useToastContext } from "../../components/Toast/Toast";

// State and API imports for paginations
import StoreScopedUser from "../../data/store/ScopedUser";
import StoreScopedDomain from "../../data/store/ScopedDomain";
import StoreScopedRequest from "../../data/store/ScopedRequest";
import getScopedUser from "../../api/user/getScopedUser";
import getScopedDomain from "../../api/domain/getScopedDomain";
import getScopedDomainReqs from "../../api/domain/getScopedDomainReqs";
import getScopedRequest from "../../api/request/getScopedRequest";
import StoreRequestsList from "../../data/store/RequestsList";
import StoreUsersList from "../../data/store/UsersList";
import StoreDomainsList from "../../data/store/DomainsList";
import SnapModal from "../../data/store/Modal";

const Modal = ({
  isModalOpen,
  title,
  subTitle,
  children,
  status,
  type,
  pagination = true,
  fluid,
}) => {
  const { modalOpen, modalType } = useContext(ModalContext);
  const { fullscreen } = useSnapshot(SnapScopedRequest);
  // Pagination variables start
  const { request: scoepdRequest } = useSnapshot(StoreScopedRequest);
  const { user: scopedUser } = useSnapshot(StoreScopedUser);
  const { domain: scopedDomain } = useSnapshot(StoreScopedDomain);
  const requestsList = useSnapshot(StoreRequestsList);
  const usersList = useSnapshot(StoreUsersList);
  const domainsList = useSnapshot(StoreDomainsList);
  const { modal_type } = useSnapshot(SnapModal);
  const [currentPendingIndex, setCurrentPendingIndex] = useState();
  const [sortedData, setSortedData] = useState([]);
  const [canUsePaginator, setCanUsePaginator] = useState(false);
  const [prevStatus, setPrevStatus] = useState();
  const [nextStatus, setNextStatus] = useState();
  const addToast = useToastContext();
  // Pagination variables end
  const openModalWithContent = () => {
    let body = {
      token: localStorage.getItem("userToken"),
      id: scopedUser.domain_id,
    };
    getScopedDomain(body, addToast);
    getScopedDomainReqs(body, addToast);
    modalType("domain");
  };
  // Close modal and init to default state
  const closeModalInits = (e) => {
    e.preventDefault();
    modalOpen(false);
    // Init toolbar in modal
    setToolbar(0);
  };
  // Pagination functions
  // Get prev/next user id
  const getID = (type, current) => {
    let requestedID, idKey;
    switch (true) {
      case modal_type === "requests":
        idKey = "id";
        break;
      case modal_type === "users":
        idKey = "id";
        break;
      case modal_type === "domain":
        idKey = "domain_id";
        break;
      default:
        break;
    }
    if (type === "prev") requestedID = sortedData[current - 1][idKey];
    if (type === "next") requestedID = sortedData[current + 1][idKey];
    return requestedID;
  };
  // Let request body
  const createBody = (type) => {
    let body = {
      token: localStorage.getItem("userToken"),
    };
    if (type === "prev") {
      body = {
        ...body,
        id: getID("prev", currentPendingIndex),
      };
    }
    if (type === "next") {
      body = {
        ...body,
        id: getID("next", currentPendingIndex),
      };
    }
    return body;
  };
  // Pagination function
  const prevUser = () => {
    if (modal_type === "requests") {
      ClearScopedRequest();
      getScopedRequest(createBody("prev"), addToast);
    }
    if (modal_type === "users") getScopedUser(createBody("prev"), addToast);
    if (modal_type === "domain") {
      getScopedDomain(createBody("prev"), addToast);
    }
  };
  const nextUser = () => {
    if (modal_type === "requests") {
      ClearScopedRequest();
      getScopedRequest(createBody("next"), addToast);
    }
    if (modal_type === "users") getScopedUser(createBody("next"), addToast);
    if (modal_type === "domain") {
      getScopedDomain(createBody("next"), addToast);
    }
  };
  // Init pagination
  useEffect(() => {
    let filteredData;
    const setIndex = (indexOfScopedReq, filteredData) => {
      // Set scoped index
      setCurrentPendingIndex(indexOfScopedReq);

      // Set paginator prev status
      if (indexOfScopedReq === 0) setPrevStatus(false);
      else setPrevStatus(true);

      // Set paginator prev status
      if (indexOfScopedReq === filteredData.length - 1) setNextStatus(false);
      else setNextStatus(true);
    };

    // Requests pagination
    if (modal_type === "requests") {
      filteredData = requestsList;
      // Filter function
      filteredData = filteredData.filter(
        (item) => item.status && item.status === "Pending"
      );
      // Get comparable date (create number from date)
      const converterData = (data) => {
        let comparableData = data
          .replaceAll(".", "")
          .replaceAll(" ", "")
          .replaceAll(":", "");
        comparableData = Math.abs(parseInt(comparableData));
        return comparableData;
      };

      // Sorting table
      filteredData.sort(
        (a, b) => converterData(a.created_at) - converterData(b.created_at)
      );

      // Add sorted list to reference array
      setSortedData(
        filteredData.sort(
          (a, b) => converterData(a.created_at) - converterData(b.created_at)
        )
      );

      // Get index of scoped request when user open it
      const indexOfScopedReq = filteredData.findIndex(
        (request) => request.id === scoepdRequest.request_id
      );
      // Set scoped index
      setIndex(indexOfScopedReq, filteredData);
    }

    // Users pagination
    if (modal_type === "users") {
      filteredData = usersList;
      // Filter function
      filteredData = filteredData.filter(
        (user) => user.reg_date && user.reg_date !== ""
      );
      // Get comparable date (create number from date)
      const converterData = (data) => {
        let comparableData = data
          .replaceAll(".", "")
          .replaceAll(" ", "")
          .replaceAll(":", "");
        comparableData = Math.abs(parseInt(comparableData));
        return comparableData;
      };

      // Sorting table
      filteredData.sort(
        (a, b) => converterData(a.reg_date) - converterData(b.reg_date)
      );

      // Add sorted list to reference array
      setSortedData(
        filteredData.sort(
          (a, b) => converterData(a.reg_date) - converterData(b.reg_date)
        )
      );

      // Get index of scoped user when user open it
      const indexOfScopedReq = filteredData.findIndex(
        (user) => user.id === scopedUser.id
      );
      // Set scoped index
      setIndex(indexOfScopedReq, filteredData);
    }

    // Users pagination
    if (modal_type === "domain") {
      filteredData = domainsList;
      filteredData = filteredData.filter(
        (domain) => domain.created_at && domain.created_at !== ""
      );
      // Get comparable date (create number from date)
      const converterData = (data) => {
        let comparableData = data.replaceAll(".", "");
        comparableData = Math.abs(parseInt(comparableData));
        return comparableData;
      };

      // Sorting table
      filteredData.sort(
        (a, b) => converterData(a.created_at) - converterData(b.created_at)
      );

      // Add sorted list to reference array
      setSortedData(
        filteredData.sort(
          (a, b) => converterData(a.created_at) - converterData(b.created_at)
        )
      );

      // Get index of scoped domain when user open it
      const indexOfScopedReq = filteredData.findIndex(
        (domain) => domain.domain_id === scopedDomain.id
      );

      // Set scoped index
      setIndex(indexOfScopedReq, filteredData);
    }
  }, [
    //request_id,
    scoepdRequest.request_id,
    requestsList,
    modal_type,
    domainsList,
    scopedUser.id,
    usersList,
    scopedDomain.id,
  ]);

  // Set paginator available
  useEffect(() => {
    if (modal_type === "requests") {
      if (scoepdRequest.status === "Pending") setCanUsePaginator(true);
      if (scoepdRequest.status !== "Pending") setCanUsePaginator(false);
    } else {
      setCanUsePaginator(true);
    }
  }, [scoepdRequest.status, modal_type]);

  // Properties for paginator
  const paginatorProps = { prevStatus, nextStatus, prevUser, nextUser };
  return (
    <div
      className="modal__layout"
      style={isModalOpen ? { display: "block" } : { display: "none" }}
    >
      <div className={`${!fullscreen ? "modal__layout-centered" : ""}`}>
        <div className={`modal ${fluid ? "modal-fluid" : ""}`}>
          {pagination && canUsePaginator ? (
            <Carousel {...paginatorProps} />
          ) : (
            ""
          )}

          <div className="modal__header">
            <div>
              {type === "request" ? (
                <>
                  {pagination && canUsePaginator ? (
                    <CarouselSmall {...paginatorProps} />
                  ) : (
                    ""
                  )}
                  <h2>{title}</h2>
                  <Badge status={status} type="requests" />
                </>
              ) : (
                <div className="modal__header-flex">
                  {pagination && canUsePaginator ? (
                    <CarouselSmall {...paginatorProps} />
                  ) : (
                    ""
                  )}
                  <div>
                    <h2>{title}</h2>
                    <h5 onClick={() => openModalWithContent()}>{subTitle}</h5>
                  </div>
                </div>
              )}
            </div>
            <ButtonClose onClickEvent={(e) => closeModalInits(e)} />
          </div>
          <div className="modal__content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
