import { useState } from "react";
import Icons from "../Icons/Icons";
import { UserDomainTransformer } from "./UserDomainTransformer";

const DropDown = ({ defaultRule }) => {
  const [rule, setRule] = useState(defaultRule);
  const handleRule = (e) => {
    if (e.target.value === "admin") {
      setRule("Adminisztrátor");
    } else if (e.target.value === "user") {
      setRule("Felhasználó");
    }
  };

  return (
    <label>
      <select value={rule} onChange={handleRule}>
        <option value={rule} hidden>
          {rule}
        </option>
        <option value="admin">Adminisztrátor</option>
        <option value="user">Felhasználó</option>
      </select>
    </label>
  );
};

const UserItem = ({ myKey, email, defaultRule }) => {
  return (
    <div className="user-item" key={myKey}>
      <div className="user-item__left">
        <Icons.UserFilled />
        <div>
          <div className="user-item__left-users-domains">
           <h4>{UserDomainTransformer(email)}</h4>
          </div>
          <span className="action-buttons">
            <DropDown defaultRule={defaultRule} />
          </span>
        </div>
      </div>
    </div>
  );
};

const Users = ({ usersList }) => {
  return (
    <>
      {usersList.map((user) => {
        return (
          <UserItem
            key={user.id}
            myKey={user.id}
            first_name={user.first_name}
            last_name={user.last_name}
            domain={user.domain}
            email={user.email}
            rule={user.rule}
            defaultRule={user.rule}
          />
        );
      })}
    </>
  );
};

export default Users;
