// React imports
import { useContext, useState } from "react";
// Component imports
import Button from "../../Button/Button";
import Input from "../../Form/Input";
import Modal from "../Modal";
import InputDouble from "../../Form/InputDouble";
import Checkbox from "../../Form/Checkbox";
// Toast import
import { useToastContext } from "../../Toast/Toast";
// Store and API import
import createDomain from "../../../api/domain/createDomain";
// Modal import 
import { ModalContext } from "../../Router/Router";

const ModalDomainNew = ({ isModalOpen }) => {
  const [formData, setFormData] = useState({});
  const { modalOpen } = useContext(ModalContext);
  const addToast = useToastContext();

  // Handle input change
  const handleInput = (e) => {
    setFormData((prevFormData) => {
      const { name, value, type, checked } = e.target;
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create request body
    let body = {
      token: localStorage.getItem("userToken"),
      domain_name: formData.domain,
      company_name: formData.company_name,
      company_postal: formData.company_postal,
      company_city: formData.company_city,
      company_address: formData.company_address,
      company_vat: formData.company_vat,
      on_trial: formData.on_trial,
    };

    // Call create new domain endpoint
    createDomain(body, addToast, modalOpen, setFormData);
  };


  return (
    <Modal
      isModalOpen={isModalOpen}
      type="domain"
      xxlModal="true"
      title="Új domain létrehozása"
      pagination={false}
    >
      <main>
        <form
          className="modal__form"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="modal__form__fields">
            <div className="modal__form__fields-group">
              <Input
                label="Domain"
                type="text"
                value={formData.domain === undefined ? "" : formData.domain}
                onChange={handleInput}
                name="domain"
              />
              <Input
                label="Cég"
                type="text"
                value={
                  formData.company_name === undefined
                    ? ""
                    : formData.company_name
                }
                onChange={handleInput}
                name="company_name"
              />
              <InputDouble
                labelLeft="IRSZ"
                labelRight="Város"
                typeLeft="text"
                typeRight="text"
                valueLeft={
                  formData.company_postal === undefined
                    ? ""
                    : formData.company_postal
                }
                valueRight={
                  formData.company_city === undefined
                    ? ""
                    : formData.company_city
                }
                onChangeLeft={handleInput}
                onChangeRight={handleInput}
                nameLeft="company_postal"
                nameRight="company_city"
              />
              <Input
                label="Cím"
                type="text"
                value={
                  formData.company_address === undefined
                    ? ""
                    : formData.company_address
                }
                onChange={handleInput}
                name="company_address"
              />
              <Input
                label="Adószám"
                type="text"
                value={
                  formData.company_vat === undefined ? "" : formData.company_vat
                }
                onChange={handleInput}
                name="company_vat"
              />
              <Checkbox
                label="Tesztidőszakra van állítva a domain?"
                name="on_trial"
                value={formData.on_trial === undefined ? "" : formData.on_trial}
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="modal__actions">
            <Button actionType="submit">Új domain létrehozása</Button>
          </div>
        </form>
      </main>
    </Modal>
  );
};

export default ModalDomainNew;
