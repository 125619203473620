// React imports
import { useState, useEffect } from "react";
// Component imports
import Modal from "../Modal";
import CardModal from "../../Cards/CardModal";
import MapView from "../../MapView/MapView";
import ModelView from "../../ModelView/ModelView";
import Viewselector from "../../Viewselector/Viewselector";
import Select from "../../Form/Select";
import Button from "../../Button/Button";
// Partial imports
import ModalRequestDenied from "../Partials/ModalRequestDenied";
import ModalRequestFiles from "../Partials/ModalRequestFiles";
// Toast import
import { useToastContext } from "../../Toast/Toast";
// Valtio and store imports
import { useSnapshot } from "valtio";
import StoreScopedRequest, {
  setToolbar,
} from "../../../data/store/ScopedRequest";
// API
import editScopedRequest from "../../../api/request/editRequest";

const ModalRequest = ({ isModalOpen }) => {
  const { request, toolbar, fullscreen } = useSnapshot(StoreScopedRequest);
  const addToast = useToastContext();

  const [formData, setFormData] = useState({
    status: "Pending",
    comment: "",
  });

  const statusOptions = [
    { key: "Pending", value: "Pending", label: "Várakozik" },
    { key: "Available", value: "Available", label: "Teljesítve" },
    { key: "Denied", value: "Denied", label: "Elutasítva" },
  ];

  // Set initial state
  useEffect(() => {
    if (request.request_id) {
      setFormData({
        status: request.status,
        comment: request.comment,
      });
      setToolbar(request.model_json ? 1 : 0);
    }
  }, [request]);

  // Handle input change
  const handleInput = (e) => {
    setFormData((prevFormData) => {
      const { name, value, type, checked } = e.target;
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  // Set active toolbar tab
  const handleToolbar = (i) => {
    setToolbar(i);
  };

  // Handle form submition
  const onSubmit = () => {
    const token = localStorage.getItem("userToken");
    let body = {
      token: token,
      id: request.request_id,
      status: formData.status,
      comment: formData.comment,
    };
    // Send request to API endpoint
    editScopedRequest(body, addToast);
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      type="request"
      title={`SVP-${request.request_id}`}
      status={request.status}
      fluid
    >
      <main>
        <div className="modal__cards">
          <CardModal type="date" data={request.times} />
          <CardModal type="location" data={request.coordinates} />
          <CardModal type="request-type" data={request.order_details} />
          <CardModal type="user" data={[request.user, request.domain]} />
        </div>
        <div className={`modal__view ${fullscreen ? "expanded" : ""}`}>
          <Viewselector
            handleToolbar={handleToolbar}
            toolbar={toolbar}
            hasModel={request.model_json}
          />
          {toolbar === 0
            ? <MapView />
            : <ModelView />
          }
        </div>
      </main>

      <aside>
        {formData.status !== "Denied" ? (
          <ModalRequestFiles />
        ) : (
          <ModalRequestDenied handleInput={handleInput} formData={formData} />
        )}
        <hr />
        <div className="modal__actions">
          <Select
            options={statusOptions}
            onChange={handleInput}
            name="status"
            selected={formData.status}
            value={formData.status}
          />
          <Button
            onClickEvent={onSubmit}
            buttonType={formData.status === "Denied" ? "danger" : ""}
          >
            {formData.status === "Denied" ? "Elutasítás" : "Mentés"}
          </Button>
        </div>
      </aside>
    </Modal>
  );
};

export default ModalRequest;
