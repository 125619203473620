// Imports
import { urls } from "../urls";
import { CheckError } from "../../utils/api/checkError";
import getScopedRequest from "./getScopedRequest";

// Upload files to specific request
const uploadFilesInRequest = async (body, message, removeAllFiles) => {
  await fetch(urls.requestsFileUpload + body.id, {
    method: "POST",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
    body: body.formData,
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      message(["success", "Sikeres fáj feltöltés!"]);
      // Remove files
      removeAllFiles()
      // Refresh scoped user
      getScopedRequest(body, message);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default uploadFilesInRequest;
