//Imports
import { urls } from "../urls";
import { CheckError } from "../../utils/api/checkError";
import { SetScopedUserNewDomain } from "../../data/store/ScopedUser";

// Get admin information
const editScopedUser = async (body, message) => {
  await fetch(urls.userByID + body.id + "/domain", {
    method: "PATCH",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      domain_id: body.new_domain_id,
    }),
  })
    .then((response) => CheckError(response, message, "Sikertelen domain lekérés"))
    .then(() => {
      SetScopedUserNewDomain(body.new_domain_id);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default editScopedUser;
