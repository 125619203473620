import { proxy } from "valtio";
import { formatDate, getTMinus } from "../utils";

// Default states
const StoreRequestsList = proxy([
  {
    id: "",
    service: "",
    lat: 0,
    lon: 0,
    resolved_addr: "",
    status: "",
    req_by: "",
    building_type: "",
    comment: "",
    created_at: "",
    updated_at: "",
    difference_between_dates: "",
    user: {
      id: "",
      first_name: "",
      last_name: "",
      email: "",
    },
    domain: {
      domain_id: "",
      domain_name: "",
    },
  },
]);

export const StoreRequestsListProp = proxy({
  request_list_length: null,
  request_list_pending: null,
});

// Set requests list
export const SetRequestsList = (body) => {
  // Empty array
  StoreRequestsList.splice(0, StoreRequestsList.length);
  // Mapping data and add to array
  body.map((request) => {
    let requestObj = {
      id: request.request_id,
      service: request.service,
      lat: request.lat,
      lon: request.lon,
      resolved_addr: request.resolved_addr,
      status: request.status,
      req_by: request.req_by,
      building_type: request.building_type,
      comment: request.comment,
      created_at: formatDate(request.created_at),
      updated_at: formatDate(request.updated_at),
      tminus:
        request.status === "Pending" ? getTMinus(request.created_at) : null,
      user: {
        id: request.user.id,
        first_name: request.user.first_name,
        last_name: request.user.last_name,
        email: request.user.email,
      },
      domain: {
        domain_id: request.domain.domain_id,
        domain_name: request.domain.domain_name,
      },
    };
    return StoreRequestsList.push(requestObj);
  });
  // Set request list props
  StoreRequestsListProp.request_list_length = body.length;
  let pendingRequests = body.filter((request) => request.status === "Pending");
  StoreRequestsListProp.request_list_pending = pendingRequests.length;
};

export default StoreRequestsList;
