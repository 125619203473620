// Imports
import { urls } from "../urls";
import { CheckError } from "../../utils/api/checkError";
import { SetScopedDomainReqs } from "../../data/store/ScopedDomain";

// Get selected domain
const getScopedDomainReqs = async (body, message) => {
  await fetch(urls.requestByDomain + body.id, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    }
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
        SetScopedDomainReqs(actualData)
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getScopedDomainReqs;
