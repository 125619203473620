// React
import { useState, useEffect } from "react";
// Toast import
import { useToastContext } from "../../components/Toast/Toast";
// State imports from Store
import { useSnapshot } from "valtio";
import StoreUsersList from "../../data/store/UsersList";
// API
import getUsers from "../../api/user/getUsers";
// Component Imports
import Table from "../../components/Table/Table";
import TableUsers from "../../components/Table/Tables/TableUsers";


const UsersPage = () => {
  const snap = useSnapshot(StoreUsersList);
  const addToast = useToastContext();

  // Fetch data
  useEffect(() => {
    let body = {
      token: localStorage.getItem("userToken"),
    };
    getUsers(body, addToast);
  }, [addToast]);
  
  // Filtering
  const [filterText, setFilterText] = useState('');
  const filterableColums = [
    "last_name",
    "first_name",
    "domain_name"
  ];

  // Filter function
  const filteredData = snap && snap.filter((item) => {
    for (const key of filterableColums) {
      if (item[key] && item[key].toLowerCase().includes(filterText.toLowerCase())) {
        return item;
      }
    };
    return null;
  });

  return (
    <Table
      title="Felhasználók"
      searchButtons={false}
      filterText={filterText}
      setFilterText={setFilterText}
    >
      <TableUsers
        data={filteredData}
      />
    </Table>
  );
};

export default UsersPage;