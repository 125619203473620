// Component imports
import Toolbar from "./Toolbar";
import Viewbar from "./Viewbar";


// HUD
const HUD = () => {
  return (
    <>
      <Toolbar/>
      <Viewbar/>
    </>
  )
};

export default HUD;