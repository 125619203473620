// React
import { useState, useContext, useEffect } from "react";

// React Data Table
import DataTable from "react-data-table-component";
import { columWidth, paginationComponentOptions } from "../Table";

// Router
import { ModalContext } from "../../Router/Router";

// Toast import
import { useToastContext } from "../../Toast/Toast";

// Component imports
import TableLoader from "../TableLoader";
import TableNoResults from "../TableNoResults";
import BadgeActive from "../../Badge/BadgeActive";

// API
import getScopedUser from "../../../api/user/getScopedUser";
import getScopedDomain from "../../../api/domain/getScopedDomain";
import getScopedDomainReqs from "../../../api/domain/getScopedDomainReqs";
import getDomains from "../../../api/domain/getDomains";

const TableUsers = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const { modalOpen, modalType } = useContext(ModalContext);
  const addToast = useToastContext();

  // Colums
  const colums = [
    {
      selector: (row) => <BadgeActive active={row.is_active} />,
      width: columWidth.small,
    },
    {
      name: "Felhasználó",
      selector: (row) => `${row.last_name} ${row.first_name}`,
      sortable: true,
      style: {
        fontWeight: 500,
      },
    },
    {
      name: "Domain",
      selector: (row) => row.domain_name || "-",
      sortable: true,
    },
    {
      name: "Igények",
      selector: (row) => `${row.total_requests || "0"} db`,
      sortable: true,
      width: columWidth.medium,
    },
    {
      name: "Szint",
      selector: (row) => (row.is_admin ? "Admin" : "Felhasználó"),
      sortable: true,
      width: columWidth.normal,
      hide: "md",
    },
    {
      id: "reg_date",
      name: "Regisztráció",
      selector: (row) => row.reg_date,
      sortable: true,
      width: columWidth.dateTime,
    },
    {
      name: "Bejelentkezés",
      selector: (row) => row.login_date || "-",
      sortable: true,
      width: columWidth.dateTime,
      hide: "lg",
    },
  ];

  // Conditional styleing
  const conditionalRowStyles = [
    {
      when: (row) => !row.is_active,
      style: {
        opacity: 0.75,
      },
    },
  ];

  // Loading state handling
  useEffect(() => {
    if (data.length > 1) {
      setLoading(false);
    }
  }, [data]);

  // Model open function
  const openModalWithContent = (type, id, domainID) => {
    let body = {
      token: localStorage.getItem("userToken"),
      id: id,
    };
    let bodyID = {
      token: localStorage.getItem("userToken"),
      id: domainID,
    };
    if (type === "users") {
      getScopedUser(body, addToast);
      getDomains(body, addToast);
      getScopedDomain(bodyID, addToast);
    }
    if (type === "domain") {
      getScopedDomain(body, addToast);
      getScopedDomainReqs(body, addToast);
    }
    modalOpen(true);
    modalType(type);
  };

  return (
    <DataTable
      data={data}
      columns={colums}
      conditionalRowStyles={conditionalRowStyles}
      onRowClicked={(row) => {
        openModalWithContent("users", row.id, row.domain_id);
      }}
      progressPending={loading}
      progressComponent={<TableLoader />}
      defaultSortFieldId="reg_date"
      defaultSortAsc={false}
      noDataComponent={
        <TableNoResults text="Nem található a keresésnek megfelelő felhasználó!" />
      }
      pagination
      paginationPerPage={30}
      paginationComponentOptions={paginationComponentOptions}
      responsive
    />
  );
};

export default TableUsers;
