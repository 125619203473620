// React
import { useState, useContext, useEffect } from "react";

// React Data Table
import DataTable from 'react-data-table-component';
import { columWidth, paginationComponentOptions } from "../Table";

// Router
import { ModalContext } from "../../Router/Router";

// Toast import
import { useToastContext } from "../../Toast/Toast";

// Component imports
import TableLoader from "../TableLoader";
import TableNoResults from "../TableNoResults"
import Badge from "../../Badge/Badge";

// State and API imports
import getScopedRequest from "../../../api/request/getScopedRequest.js";


const TableRequests = ({ data, expanded }) => {
  const [loading, setLoading] = useState(true);
  const { modalOpen, modalType } = useContext(ModalContext);
  const addToast = useToastContext();

  // Custom sorting for TMinus
  const customSortTMinus = (rowA, rowB) => {
    const a = rowA.tminus ? rowA.tminus.sec : 9e9
    const b = rowB.tminus ? rowB.tminus.sec : 9e9

    return a > b ? 1 : -1;
};

  // Colums
  const colums = [
    {
      id: 'tminus',
      name: "T-",
      selector: (row) => row.tminus && row.tminus.sec,
      cell: (row) => row.tminus ? <div className="badge-tminus">{row.tminus.hours}ó</div> : null,
      sortable: true,
      sortFunction: customSortTMinus,
      width: columWidth.dateCount,
      center: true,
      conditionalCellStyles: [
        {
          when: row => row.tminus && (row.tminus.hours <= 36 && row.tminus.hours > 12),
          style: {color: '#FA7A49'}
        },
        {
          when: row => row.tminus && row.tminus.hours <= 12,
          style: {color: '#FF0055'}
        }
      ],
    },
    {
      name: "Dátum",
      selector: (row) => row.created_at,
      sortable: true,
      width: columWidth.dateTime,
    },
    {
      name: "ID",
      selector: (row) => `SVP-${row.id}`,
      sortable: true,
      width: columWidth.large,
      omit: !expanded,
    },
    {
      name: "Domain",
      selector: (row) => row.domain.domain_name,
      sortable: true,
      width: columWidth.large,
      hide: 'lg',
    },
    {
      name: "Cím",
      selector: (row) => row.resolved_addr,
      sortable: true,
    },
    {
      name: "Státusz",
      selector: (row) => <Badge status={row.status} type="requests" />,
      width: columWidth.medium,
      omit: !expanded,
    },
  ];

  // Loading state handling
  useEffect(() => {
    if(data.length > 1){
      setLoading(false);
    }
  }, [data]);

  // Modal open function
  const openModalWithContent = (type, id) => {
    let body = {
      token: localStorage.getItem("userToken"),
      id: id,
    };
    getScopedRequest(body, addToast)
    modalOpen(true);
    modalType(type);
  };

  return (
    <DataTable
      data={data}
      columns={colums}
      onRowClicked={(row) => {openModalWithContent("requests", row.id)}}
      progressPending={loading}
      progressComponent={<TableLoader/>}
      defaultSortFieldId="tminus"
      noDataComponent={<TableNoResults text="Nem található a keresésnek megfelelő igény!"/>}
      pagination={expanded}
      paginationPerPage={30}
      paginationComponentOptions={paginationComponentOptions}
      responsive
    />
  );
};

export default TableRequests;
