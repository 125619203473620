import Icons from "../Icons/Icons";
import Row from "../Layout/Row";
// Valtio and store imports
import { useSnapshot } from "valtio";
import StoreScopedRequest, {
  setFullscreen,
} from "../../data/store/ScopedRequest";

const Viewselector = ({ handleToolbar, toolbar, hasModel }) => {
  const { fullscreen } = useSnapshot(StoreScopedRequest);
  return (
    <div className="viewselector">
      <Row justify={["xsSpaceBetween"]}>
        <div className="viewselector-switch">
          <button
            className={`${toolbar === 0 && "active"}`}
            onClick={() => handleToolbar(0)}
          >
            <Icons.Pin color="white" />
          </button>
          {hasModel !== null && (
            <button
              className={`${toolbar === 1 && "active"}`}
              onClick={() => handleToolbar(1)}
            >
              <Icons.Model color="white" />
            </button>
          )}
        </div>
        <button onClick={() => setFullscreen()}>
          {fullscreen ? (
            <Icons.ExpandLess color="white" />
          ) : (
            <Icons.Expand color="white" />
          )}
        </button>
      </Row>
    </div>
  );
};

export default Viewselector;
