// React
import { useState, useContext, useEffect } from "react";

// React Data Table
import DataTable from 'react-data-table-component';
import { columWidth, paginationComponentOptions } from "../Table";

// Router
import { ModalContext } from "../../Router/Router";

// Toast import
import { useToastContext } from "../../Toast/Toast";

// Component imports
import TableLoader from "../TableLoader";
import TableNoResults from "../TableNoResults"
import BadgeActive from "../../Badge/BadgeActive";

// API
import getScopedDomain from "../../../api/domain/getScopedDomain";

const TableDomains = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const { modalOpen, modalType } = useContext(ModalContext);
  const addToast = useToastContext();

  // Colums
  const colums = [
    {
      selector: (row) => <BadgeActive active={row.is_active}/>,
      width: columWidth.small,
    },
    {
      name: "Domain",
      selector: (row) => row.domain_name,
      sortable: true,
      style: {
        fontWeight: 500,
      },
    },
    {
      name: "Felhasználók",
      selector: (row) => row.users ? row.users + " db" : "-",
      sortable: true,
      width: columWidth.normal,
    },
    {
      name: "Csomag",
      selector: (row) => row.on_trial ? "Demo" : "Korlátlan",
      sortable: true,
      width: columWidth.medium,
    },
    {
      name: "Cég",
      selector: (row) => row.company_name,
      sortable: true,
    },
    {
      name: "Adószám",
      selector: (row) => row.company_vat,
      width: columWidth.normal,
    },
    {
      name: "Létrehozva",
      selector: (row) => row.created_at,
      sortable: true,
      width: columWidth.dateDate,
    },
  ];

  // Conditional styleing
  const conditionalRowStyles = [
    {
      when: row => !row.is_active,
      style: {
        opacity: 0.75,
      },
    }
  ];

  // Loading state handling
  useEffect(() => {
    if(data.length > 1){
      setLoading(false);
    }
  }, [data]);

  // Modal open function
  const openModalWithContent = (type, id) => {
    let body = {
      token: localStorage.getItem("userToken"),
      id: id,
    };
    getScopedDomain(body, addToast);
    modalOpen(true);
    modalType(type);
  };

  return (
    <DataTable
      data={data}
      columns={colums}
      conditionalRowStyles={conditionalRowStyles}
      onRowClicked={(row) => {openModalWithContent("domain", row.domain_id)}}
      progressPending={loading}
      progressComponent={<TableLoader/>}
      noDataComponent={<TableNoResults text="Nem található a keresésnek megfelelő domain!"/>}
      pagination
      paginationPerPage={30}
      paginationComponentOptions={paginationComponentOptions}
      responsive
    />
  );
};

export default TableDomains;
