// Utility functions
export const getBuildingFeature = (num) => {
  switch (true) {
    case num === 0:
      return "Épületmodell";
    case num === 1:
      return "Épületmodell + Tetőobjektumok";
    case num === 2:
      return "Épületmodell + Tetőobjektumok + Napelem ";
    default:
      break;
  }
};

export const formatDate = (unFormatedDate) => {
  // Utility functions
  const checkLength = (unformatedDateNumber) => {
    let formatedDate = unformatedDateNumber.toString();
    if (formatedDate.length < 2) formatedDate = "0" + formatedDate;
    return formatedDate;
  };

  // Return formated date
  let oldFormatedDate = unFormatedDate.slice(0, 16);
  oldFormatedDate = oldFormatedDate.replaceAll("T", " ");
  oldFormatedDate = oldFormatedDate.replaceAll("-", ".");

  const arrayDate = oldFormatedDate.split(" ");
  const arrayYearMonthDay = arrayDate[0];
  const arrayHours = checkLength(parseInt(arrayDate[1].split(":")[0]) + 1);
  const arrayMinutes = arrayDate[1].split(":")[1];

  // Create expected date format
  const formatedDate = `${arrayYearMonthDay}. ${arrayHours}:${arrayMinutes}`;

  return formatedDate;
};

export const getTMinus = (unFormatedDate) => {
  // Calculate deadline
  let date = new Date(unFormatedDate);
  date.setDate(date.getDate() + parseInt(3));
  const deadline = date.toISOString();

  // Get how far deadline is
  const total = Date.parse(deadline) - Date.parse(new Date());
  const minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  const totalhours = hours + days * 24;

  // Create object
  const tminus = {
    sec: total,
    hours: totalhours,
    minutes: minutes,
  };

  // Return value
  return tminus;
};
