import Icons from "../Icons/Icons";
import { useToastContext } from "../Toast/Toast";

export const ButtonGPSCopy = ({gps}) => {
  const addToast = useToastContext();

  // Copy-paste coord
  const handleCopyCord = (e) => {
    e.preventDefault();
    // Get coordinates and format text
    let copiedText = e.target.name
      ? `-${gps[e.target.name].toFixed(2)}`
      : `-${gps.x.toFixed(2)}, -${gps.y.toFixed(2)}, -${gps.z.toFixed(2)}`;
    // Copy the text 
    navigator.clipboard.writeText(copiedText);

    addToast(["success", e.target.name
      ? `Model ${e.target.name.toUpperCase()} koordináta másolva!`
      : "Model koordináták másolva!"]);
  };

  return (
    <div className="button-gpscopy">
      <button
        className="button button-small"
        onClick={handleCopyCord}
      >
        <Icons.Copy/>
      </button>
      <button
        className="button button-small"
        name="x"
        onClick={handleCopyCord}
      >
        X
      </button>
      <button
        className="button button-small"
        name="y"
        onClick={handleCopyCord}
      >
        Y
      </button>
      <button
        className="button button-small"
        name="z"
        onClick={handleCopyCord}
      >
        Z
      </button>
    </div>
  );
};

export default ButtonGPSCopy;