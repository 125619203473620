// React
import { useContext } from "react";

// Component imports
import SearchBar from "../SearchBar/SearchBar";
import Button from "../Button/Button";

// Router
import { ModalContext } from "../Router/Router";


// React Data Table Defaults
export const columWidth = {
  small: "4rem",
  medium: "8rem",
  normal: "10rem",
  large: "12rem",
  dateCount: "6rem",
  dateDate: "9rem",
  dateTime: "11rem"
};
export const paginationComponentOptions = {
  rangeSeparatorText: '/',
  selectAllRowsItem: true,
  noRowsPerPage: true,
};


const HeaderButtons = () => {
  const { modalOpen, modalType } = useContext(ModalContext);

  const openModalWithContent = (type) => {
    modalOpen(true);
    modalType(type);
  };

  return (
    <div onClick={() => openModalWithContent("domainNew")}>
      <Button>Új domain</Button>
    </div>
  );
};


const Table = ({
  title,
  children,
  searchButtons = false,
  filterText,
  setFilterText
}) => {
  return (
    <section className="table__box">
      <div className="table__box__header">
        <h2>{title}</h2>
        <div className="table__box__header__actions">
          <SearchBar
            placeholder="Keresés..."
            filterText={filterText}
            setFilterText={setFilterText}
          />
          {searchButtons && <HeaderButtons />}
        </div>
      </div>
      <div className="table__box__table">
        {children}
      </div>
    </section>
  );
};

export default Table;
