//Imports
import { urls } from "../urls";
import { SetAdminName, SetAdminRole } from "../../data/store/AdminData";
import { CheckError } from "../../utils/api/checkError";

// Get admin information
const getAdmin = async (body, message) => {
  await fetch(urls.profile, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      // Set admin name
      SetAdminName(actualData.full_name)

      // Set admin role
      SetAdminRole(actualData.role)
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getAdmin;
