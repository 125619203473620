// Import
import { urls } from "../urls";
import { CheckError } from "../../utils/api/checkError";

// Forgotten password
const forgottenPass = async (body, errorMsg) => {
  console.log(body);
  await fetch(urls.forgottenPass, {
    method: "PATCH",
    headers: {
      accept: "application/json",
    },
  })
    .then((response) => CheckError(response, errorMsg))
    .then((actualData) => {
      console.log(actualData);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default forgottenPass;
