// Imports
import { urls } from "../urls";
import { SetScopedDomain } from "../../data/store/ScopedDomain";
import { CheckError } from "../../utils/api/checkError";
import getRequests from "../request/getRequests";
import getScopedDomainReqs from "./getScopedDomainReqs";

// Get selected domain
const getScopedDomain = async (body, message) => {
  await fetch(urls.domainByID + body.id, {
    method: "GET",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    }
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
        getRequests(body, message)
        SetScopedDomain(actualData)
        getScopedDomainReqs(body, message)
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getScopedDomain;
