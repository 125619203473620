//Imports
import { urls } from "../urls";
import { CheckError } from "../../utils/api/checkError";

// Patch scoped domain
const editScopedDomain = async (body, message, modalOpen) => {
  await fetch(urls.domainByID + body.domain_id, {
    method: "PATCH",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      domain_name: body.domain_name,
      company_name: body.company_name,
      company_postal: body.company_postal,
      company_city: body.company_city,
      company_address: body.company_address,
      company_vat: body.company_vat,
      on_trial: body.package,
    }),
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      // Send success message to user
      message(["success", "Változtatások mentése sikeres."]);
      // Close modal
      modalOpen(false);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default editScopedDomain;
