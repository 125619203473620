import { proxy } from "valtio";

// Default states
const SnapModal = proxy({
    modal_type: "",
});

// Set user old password
export const SetModalType = (value) => SnapModal.modal_type = value;


export default SnapModal