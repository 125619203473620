// Imports
import { urls } from "../urls";
// Store
import { SetScopedRequestPCD } from "../../data/store/ScopedRequest";


// Check error
const CheckError = (response) => {
  if (response.status === 200) {
    return response.blob();
  } else {
    throw Error(response.statusText);
  }
};

// Get PCD File
const getPCDFile = async (body, message) => {

  await fetch(urls.fileByID + body.id + "/pcd", {
    method: "GET",
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + body.token,
    },
  })
    // Return response data as blob and checking errors
    .then((response) => CheckError(response, message))
    .then((bytes) => {

      // Create Object URL
      const pcdObj = URL.createObjectURL(bytes);
      // Set PCD
      SetScopedRequestPCD(pcdObj);
    }).catch((error) => {
      console.log(error);
    })
};

export default getPCDFile;