import { useState, useEffect } from "react";
import Icons from "../Icons/Icons";
import ContainerFluid from "./ContainerFluid";
import { NavLink } from "react-router-dom";
// import { ModalContext } from "../Router/Router";
import { SetUserLoggedIn } from "../../data/store/Login";

const NavLinks = [
  {
    text: "Dashboard",
    link: "/",
  },
  {
    text: "Igények",
    link: "/requests",
  },
  {
    text: "Felhasználók",
    link: "/users",
  },
  {
    text: "Domainek",
    link: "/domains",
  },
];

/*
const ProfileLinks = [
  {
    text: "Fiók",
    link: "profile",
  },
];
*/

const ProfileMenu = ({ setProfileMenuOpen }) => {
  // const { modalOpen, modalType } = useContext(ModalContext);

  const handleLogout = (e) => {
    e.preventDefault();
    // Save state to localstorage
    localStorage.clear();

    // Reload window
    window.location.reload();
    SetUserLoggedIn(false);
  };

  return (
    <div className="header__content__profile-menu">
      <ul>
        {/*
          {ProfileLinks.map((element, index) => {
            return (
              <li key={index}>
                <NavLink
                  onClick={(e) => {
                    e.preventDefault();
                    modalOpen(true);
                    modalType(element.link);
                    setProfileMenuOpen(false);
                  }}
                  to={element.link}
                >
                  {element.text}
                </NavLink>
              </li>
            );
          })}
          <hr />
        */}
        <li>
          <a href="/" onClick={(e) => handleLogout(e)}>
            Kijelentkezés
            <Icons.ArrowForward />
          </a>
        </li>
      </ul>
    </div>
  );
};

const NavbarOverlay = ({ menuOpen, setMenuOpen }) => {
  return (
    <div
      className={
        menuOpen
          ? "header__mobile-overlay header__mobile-overlay-active"
          : "header__mobile-overlay"
      }
      onClick={() => {
        setMenuOpen(!menuOpen);
      }}
    ></div>
  );
};

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  useEffect(() => {
    menuOpen ? OpenModalFunction() : (document.body.style.overflow = "");
    return () => {};
  }, [menuOpen]);

  const OpenModalFunction = () => {
    document.body.style.overflow = "hidden";
  };
  return (
    <ContainerFluid>
      <div className="header__content">
        <NavbarOverlay menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <div className="header__content__left">
          <NavLink to="/" className="header__content__left__logo">
            <Icons.Logo />
          </NavLink>
          <h3 className="header__content__left__title">Service Admin</h3>
        </div>
        <nav
          className={`header__content__nav ${
            menuOpen ? "header__content__nav-menu-open" : ""
          }`}
        >
          <ul>
            <div className="header__content__nav-close">
              <button
                className="header__content__nav-close-button"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
              >
                <Icons.Close />
              </button>
            </div>
            {NavLinks.map((element, index) => {
              return (
                <li key={index}>
                  <NavLink to={element.link} onClick={() => setMenuOpen(false)}>
                    {element.text}
                  </NavLink>
                </li>
              );
            })}
            <li>
              <button
                className="header__content-profile-button"
                onClick={() => {
                  setProfileMenuOpen(!profileMenuOpen);
                }}
              >
                <Icons.User />
                <Icons.ArrowDropDown />
              </button>
              {profileMenuOpen ? (
                <ProfileMenu setProfileMenuOpen={setProfileMenuOpen} />
              ) : (
                ""
              )}
            </li>
          </ul>
          <div className="header__content__nav-mobile-menu">
            <ProfileMenu setProfileMenuOpen={setProfileMenuOpen} />
          </div>
        </nav>
        <div className="header__content__toggle">
          <button
            className="header__content__toggle__nav-hamburger-button"
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          >
            <Icons.Hamburger />
          </button>
        </div>
      </div>
    </ContainerFluid>
  );
};

export default Navbar;
