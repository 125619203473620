// React imports
import { createContext, useRef, useState, useEffect } from "react";

// OL - Map and View
import Map from "ol/Map";
import View from "ol/View";

// OL - Projection
import { transform, fromLonLat } from "ol/proj";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";

// Layer
import { modelortoLayer } from "../MapView/Layers";

// State and Valtio imports
import { useSnapshot } from "valtio";
import StoreModel, {
  changeOrtomapChangeTrack
} from "./StoreModel";

// Create MapContext context
const MapContext = createContext();

// Define EPSG:23700 used by the building's JSON data
proj4.defs('EPSG:23700',
  '+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs');
register(proj4);


// ORTOMAP
const Ortomap = () => {
  const { gps } = useSnapshot(StoreModel.model);

  const ortomapRef = useRef();
  const [ortomap, setOrtomap] = useState(null);

  // --- MOUNT MAP ---
  useEffect(() => {
    if (!gps.x) return;

    const baseProjection = "EPSG:23700";
    const convertedGPS = transform([gps.x, gps.y],"EPSG:23700","EPSG:4326");

    let options = {
      view: new View({
        projection: baseProjection,
        center: fromLonLat(convertedGPS, baseProjection),
        resolution: 0.1,
      }),
      layers: [modelortoLayer],
    };

    let mapObject = new Map(options);
    mapObject.setTarget(ortomapRef.current);
    setOrtomap(mapObject);
    return () => mapObject.setTarget(undefined);
  }, [gps]);

  useEffect(() => {
    if (!ortomap) return;
    ortomap.on("loadend", () => {
      // Pass succesfull load to store
      changeOrtomapChangeTrack();
      // Check orto availability
      const pixelData = modelortoLayer.getData([750, 750]);
      const availability = pixelData && pixelData[3] !== 0 ? true : false;
      // Pass availability to store
      StoreModel.view.ortoAvailable = availability;
    });
  }, [ortomap]);

  return (
    <MapContext.Provider value={{ map: ortomap }}>
      <div
        id="ortomap"
        className="ortomap"
        ref={ortomapRef}
      />
    </MapContext.Provider>
  )
};

export default Ortomap;