// Components import
import Icons from "../../components/Icons/Icons";

const EmptyList = ({ icon, headline, message }) => {
  return (
    <div className="empty-list">
      <div className="empty-list__icon">{icon || <Icons.SadPin />}</div>
      <div className="empty-list__content">
        <h4>{headline}</h4>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default EmptyList;
