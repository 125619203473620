const Select = ({ selected, label, options, name, onChange }) => {
  return (
    <div className="select">
      <label>
        {label}
        <select name={name} value={selected} onChange={onChange}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default Select;
