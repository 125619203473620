import * as THREE from "three";


const Solar = ({ data, view }) => {
  return (
    <>
      <mesh
        geometry={data}
        castShadow
        receiveShadow
      >
        <SolarTexture view={view} />
      </mesh>
    </>
  )
};

const SolarTexture = ({ view }) => {
  
  const props = {
    side: THREE.DoubleSide,
    transparent: true
  };

  const opacity = [ 1, 0.3, 0.6, 1 ];
  const fillColor = "#0A0A29";

  switch(view) {
    case 0:
      return (
        <meshBasicMaterial
          opacity={opacity[0]}
          color={fillColor}
          {... props}
        />
      )
    case 1:
      return (
        <meshBasicMaterial
          opacity={opacity[1]}
          color={fillColor}
          {... props}
        />
      )
    case 2:
      return (
        <meshBasicMaterial
          opacity={opacity[2]}
          color={fillColor}
          {... props}
        />
      )
    case 3:
      return (
        <meshBasicMaterial
          opacity={opacity[3]}
          color={fillColor}
          {... props}
        />
      )
    default:
      return null
  }
};

export default Solar;