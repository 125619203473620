import { proxy } from "valtio";

// Default states
const SnapNewPass = proxy({
  oldPassword: "",
  newPassword: ""
});

// Set user old password
export const SetOldPass = (value) => SnapNewPass.oldPassword = value;

// Set user new passwod
export const SetNewPass = (value) => SnapNewPass.newPassword = value;


export default SnapNewPass