import { proxy } from "valtio";
import StoreDomainsList from "./DomainsList"

const StoreScopedUser = proxy({
  user: {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    is_active: false,
    is_admin: false,
    reg_date: "",
    company: "",
    company_vat: "",
    market_segment: "",
    employee_number: "",
    domain_id: "",
    domain_name: "",
  },
  user_last_requests: [
    {
      request_id: "",
      resolved_addr: "",
      status: "",
    },
  ],
});

// Populate scoped user object with data.
// This data is returned for the user modal.
export const SetScopedUser = (scopedUser) => {
  StoreScopedUser.user = {
    id: scopedUser.id,
    first_name: scopedUser.first_name,
    last_name: scopedUser.last_name,
    email: scopedUser.email,
    phone: scopedUser.phone,
    is_active: scopedUser.is_active,
    is_admin: scopedUser.is_admin,
    reg_date: scopedUser.reg_date,
    company: scopedUser.company,
    company_vat: scopedUser.company_vat,
    market_segment: scopedUser.market_segment,
    employee_number: scopedUser.employee_number,
    domain_id: scopedUser.domain.domain_id,
    domain_name: scopedUser.domain.domain_name,
  };
};

export const SetScopedUserActive = (value) => {
  StoreScopedUser.user = {
    is_active: value,
  };
};

export const SetScopedUserPrevReqs = (list) => {
  StoreScopedUser.user_last_requests = [];
  list.map((e) => {
    let prevReq = {
      request_id: e.request_id, 
      resolved_addr: e.resolved_addr,
      status: e.status,
    };
    return StoreScopedUser.user_last_requests.push(prevReq);
  });
};

export const SetScopedUserNewDomain = (value) => {
  let newDomain = StoreDomainsList.filter((domain) => domain.domain_id === value)
  StoreScopedUser.user = {
    domain_id: value,
    domain_name: newDomain[0].domain_name,
  };
};

export default StoreScopedUser;
