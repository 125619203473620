import ModalRequest from "./Modals/ModalRequest";
import ModalUser from "./Modals/ModalUser";
import ModalDomain from "./Modals/ModalDomain";
import ModalDomainNew from "./Modals/ModalDomainNew";
import ModalProfile from "./Modals/ModalProfile";
import ModalUsersDropDown from "./Modals/ModalUsersDropDown";
import { SetModalType } from "../../data/store/Modal";

const ModalManager = ({ isModalOpen, type }) => {
  switch (true) {
    case type === "requests":
      SetModalType("requests")
      return <ModalRequest isModalOpen={isModalOpen} />;
    case type === "users":
      SetModalType("users")
      return <ModalUser isModalOpen={isModalOpen} />;
    case type === "domain":
      SetModalType("domain")
      return <ModalDomain isModalOpen={isModalOpen} />;
    case type === "domainNew":
      return <ModalDomainNew isModalOpen={isModalOpen} />;
    case type === "profile":
      return <ModalProfile isModalOpen={isModalOpen} />;
    case type === "usersDropDown":
      return <ModalUsersDropDown isModalOpen={isModalOpen} />;
    default:
      break;
  }
};

export default ModalManager;
