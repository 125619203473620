// State and Valtio imports
import StoreModel, {
  setCurrentTool
} from "../StoreModel";
import { useSnapshot } from "valtio";

// Component imports
import Icons from "../../Icons/Icons";


// TOOLBAR
const Toolbar = () => {
  return (
    <ul className="toolbar">
      <CursorTool/>
      <RulerTool/>
    </ul>
  )
};

const CursorTool = () => {
  const { currentTool, tools } = useSnapshot(StoreModel.tool);
  const { shiftPressed } = useSnapshot(StoreModel.cursor);

  return(
    <li 
      className={currentTool === 0 ? "active" : ""}
      title={tools[0]}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentTool(0)
      }}
    >
      <Icons.Cursor
        multi={shiftPressed}
        color={currentTool === 0 ? null : "white"}
      />
    </li>
  )
};

const RulerTool = () => {
  const { currentTool, tools } = useSnapshot(StoreModel.tool);

  return(
    <li 
      className={currentTool === 1 ? "active" : ""}
      title={tools[1]}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentTool(1)
      }}
    >
      <Icons.Ruler
        color={currentTool === 1 ? null : "white"}
      />
    </li>
  )
};

export default Toolbar;