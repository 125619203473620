// React imports
import React, { createContext, useState, useEffect, useRef } from "react";
// OpenLayers imports
import Map from "ol/Map";
import View from "ol/View";
import { fromLonLat } from "ol/proj";
import { defaults } from "ol/control/defaults";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
// Map Layers
import {
  backgroundLayer,
  satalaiteLayer,
  ortoLayer,
  pinLayer
} from "./Layers";
// Valtio and store imports
import { useSnapshot } from "valtio";
import StoreScopedRequest from "../../data/store/ScopedRequest";

// Create MapContext context
const MapContext = createContext();

// Map component
const MapView = () => {
  const { request, fullscreen } = useSnapshot(StoreScopedRequest);
  const mapRef = useRef();
  const [map, setMap] = useState(null);

  // MOUNT MAP
  useEffect(() => {
    // Default map config
    const baseViewProjection = "EPSG:3857",
      baseViewCenter = [
        parseFloat(request.coordinates.lng),
        parseFloat(request.coordinates.lat),
      ],
      //baseViewCenter = [parseFloat("19.503304"), parseFloat("47.162494")],
      baseViewWMCenter = fromLonLat(baseViewCenter, baseViewProjection),
      initZoom = 17,
      maxViewExtent = () => {
        const c = baseViewWMCenter;
        const p = 10 * 1000;
        return [c[0] - p, c[1] - p, c[0] + p, c[1] + p];
      };

    // Map options
    let options = {
      view: new View({
        center: baseViewWMCenter,
        zoom: initZoom,
        projection: baseViewProjection,
        extent: maxViewExtent(),
        maxZoom: 20,
      }),
      layers: [
        backgroundLayer,
        satalaiteLayer,
        ortoLayer,
        pinLayer,
      ],
      controls: defaults({
        zoom: false,
        rotate: false,
      }),
    };

    // Icon Feature
    const iconFeature = new Feature({
      geometry: new Point(baseViewWMCenter),
    });

    let mapObject = new Map(options);
    mapObject.setTarget(mapRef.current);
    pinLayer.getSource().addFeature(iconFeature);
    setMap(mapObject);
    return () => mapObject.setTarget(undefined);
  }, [request.coordinates]);

  // Update map size when expanded
  useEffect(() => {
    if (map) {
      map.updateSize();
    }
  }, [map, fullscreen]);

  // Return map context
  return (
    <MapContext.Provider value={{ map: map }}>
      <div ref={mapRef} className={"map"} />
    </MapContext.Provider>
  );
};

export default MapView;
