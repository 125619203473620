// React
import { useState, useEffect } from "react";
// Toast import
import { useToastContext } from "../../components/Toast/Toast";
// State imports from Store
import { useSnapshot } from "valtio";
import StoreRequestsList from "../../data/store/RequestsList";
// API
import getRequests from "../../api/request/getRequests";
// Component Imports
import Table from "../../components/Table/Table";
import TableRequests from "../../components/Table/Tables/TableRequests";



const RequestsPage = () => {
  const snap = useSnapshot(StoreRequestsList);
  const addToast = useToastContext();

  // Fetch data
  useEffect(() => {
    let body = {
      token: localStorage.getItem("userToken"),
    };
    getRequests(body, addToast);
  }, [addToast]);

  // Filtering
  const [filterText, setFilterText] = useState('');
  const filterableColums = [
    "id",
    "created_at",
    "resolved_addr",
  ];

  // Filter function
  const filteredData = snap && snap.filter((item) => {
    for (const key of filterableColums) {
      if (item[key] && item[key].toString().toLowerCase().includes(filterText.toLowerCase())) {
        return item;
      }
    };
    return null;
  });

  return (
    <Table
      title="Téradat igények"
      searchButtons={false}
      filterText={filterText}
      setFilterText={setFilterText}
    >
      <TableRequests
        data={filteredData}
        expanded={true}
      />
    </Table>
  );
};

export default RequestsPage;
