// React
import { useState, useEffect } from "react";
// Toast import
import { useToastContext } from "../../components/Toast/Toast";
// State imports from Store
import { useSnapshot } from "valtio";
import StoreDomainsList from "../../data/store/DomainsList";
// API
import SetDomainsList from "../../api/domain/getDomains";
// Component Imports
import Table from "../../components/Table/Table";
import TableDomains from "../../components/Table/Tables/TableDomains";


const DomainsPage = () => {
  const snap = useSnapshot(StoreDomainsList);
  const addToast = useToastContext();

  // Fetch data
  useEffect(() => {
    let body = {
      token: localStorage.getItem("userToken"),
    };
    SetDomainsList(body, addToast);
  }, [addToast]);

  // Filtering
  const [filterText, setFilterText] = useState('');
  const filterableColums = [
    "domain_name",
    "company_name",
    "company_vat"
  ];

  // Filter function
  const filteredData = snap && snap.filter((item) => {
    for (const key of filterableColums) {
      if (item[key] && item[key].toLowerCase().includes(filterText.toLowerCase())) {
        return item;
      }
    };
    return null;
  });
  return (
    <Table
      title="Domainek"
      searchButtons={true}
      filterText={filterText}
      setFilterText={setFilterText}
    >
      <TableDomains
        data={filteredData}
      />
    </Table>
  );
};

export default DomainsPage;