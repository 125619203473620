import { proxy } from "valtio";

// Default states
const StoreDomainsList = proxy([
  {
    domain_id: "",
    domain_name: "",
    company_name: "",
    company_postal: "",
    company_city: "",
    company_address: "",
    company_vat: "",
    on_trial: true,
    created_at: "",
    is_active: true,
    users: null,
  },
]);

// Set users list
export const SetDomainsList = (body) => {
  // Empty array
  StoreDomainsList.splice(0, StoreDomainsList.length);
  
  // Mapping data and add to array
  body.map((domain) => {
    let domainObj = {
      domain_id: domain.domain_id,
      domain_name: domain.domain_name,
      company_name: domain.company_name,
      company_postal: domain.company_postal,
      company_city: domain.company_city,
      company_address: domain.company_address,
      company_vat: domain.company_vat ? formatVat(domain.company_vat) : "",
      users: domain.totalusers,
      on_trial: domain.on_trial,
      is_active: domain.is_active,
      created_at: formatRegistrationDate(domain.created_at),
    };
    return StoreDomainsList.push(domainObj);
  });
};

const formatVat = (unFormatedVat) => {
  let char, positionA, positionB, preformated, formatedDate, sliceUtility;
  sliceUtility = (formattingDate, position, char) =>
    [
      formattingDate.slice(0, position),
      char,
      formattingDate.slice(position),
    ].join("");
  char = "-";
  positionA = 8;
  positionB = 10;
  preformated = sliceUtility(unFormatedVat, positionA, char);
  formatedDate = sliceUtility(preformated, positionB, char);
  return formatedDate;
};

const formatRegistrationDate = (unFormatedDate) => {
  let formatedDate = unFormatedDate.slice(0, 10);
  formatedDate = formatedDate.replaceAll("-", ".");
  return formatedDate;
};

export default StoreDomainsList;
