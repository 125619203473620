// Imports
import { urls } from "../urls";
import { SetUsersList } from "../../data/store/UsersList";
import { CheckError } from "../../utils/api/checkError";

// Get users list
const getUsers = async (body, message) => {
  await fetch(urls.users, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    }
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      SetUsersList(actualData)
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getUsers;
