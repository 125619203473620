import { proxy } from "valtio";

const StoreScopedDomain = proxy({
  domain: {
    id: "",
    domain_id: "",
    domain: "",
    package: "",
    company_name: "",
    company_postal: "",
    company_city: "",
    company_address: "",
    company_vat: "",
    created_at: "",
    requests_length: null,
  },
  users: [],
  requests: [],
  domain_requests: [
    {
      request_id: "",
      resolved_addr: "",
      status: "",
    },
  ],
});

// Populate scoped domain object with data.
// This data is returned for the domain modal.
export const SetScopedDomain = (scopedDomain) => {
  StoreScopedDomain.domain = {
    id: scopedDomain.domain_id,
    domain_id: scopedDomain.domain_id,
    domain:
      scopedDomain.domain_name === null
        ? "Nincs érték"
        : scopedDomain.domain_name,
    company_name:
      scopedDomain.company_name === null
        ? "Nincs érték"
        : scopedDomain.company_name,
    company_postal:
      scopedDomain.company_postal === null
        ? "Nincs érték"
        : scopedDomain.company_postal,
    company_city:
      scopedDomain.company_city === null
        ? "Nincs érték"
        : scopedDomain.company_city,
    company_address:
      scopedDomain.company_address === null
        ? "Nincs érték"
        : scopedDomain.company_address,
    company_vat:
      scopedDomain.company_vat === null
        ? "Nincs érték"
        : scopedDomain.company_vat,
    package: scopedDomain.on_trial,
    created_at: scopedDomain.created_at,
  };
  // Populate users to scoped domain
  StoreScopedDomain.users = [];
  scopedDomain.users.map((user) => {
    let userObject = {
      id: user.id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      domain: "teszt.hu",
      rule: "Adminisztrátor",
      defaultRule: "Felhasználó",
    };
    return StoreScopedDomain.users.push(userObject);
  });
};

// Set domain's requests length 
export const SetScopedDomainReqs = (requestsList) => {
  StoreScopedDomain.domain.requests_length = requestsList.length;
};

// Populate domain's requests 
export const SetScopedDomainPrevReqs = (list) => {
  StoreScopedDomain.domain_requests = [];
  let filteredList = list.filter((request) => request.domain_id === StoreScopedDomain.domain.domain_id)
  filteredList.map((e) => {
    let prevReq = {
      request_id: e.request_id, 
      resolved_addr: e.resolved_addr,
      status: e.status,
    };
    return StoreScopedDomain.domain_requests.push(prevReq);
  });
};

export default StoreScopedDomain;
