import { proxy } from "valtio";
import { getBuildingFeature, formatDate, getTMinus } from "../utils";


const requestInit = {
  request_id: null,
  status: "",
  status_initial: "",
  service: "",
  order_details: {
    building_features: "",
    building_type: "",
  },
  times: {
    created_at: "",
    updated_at: "",
    tminus: "",
  },
  coordinates: {
    lat: 0,
    lng: 0,
  },
  resolved_addr: "",
  panel_size: [],
  domain: {
    domain_id: "",
    domain_name: "",
  },
  user: {
    id: "",
    email: "",
    last_name: "",
    first_name: "",
  },
  comment: "",
  model_json: null,
  pcd_file: null,
};


// STORE
const StoreScopedRequest = proxy({
  request: requestInit,
  toolbar: 0,
  fullscreen: false,
});


// Toolbar switch
export const setToolbar = (value) => {
  StoreScopedRequest.toolbar = value;
};

// Set scoped request
export const SetScopedRequest = (request) => {
  StoreScopedRequest.request = {
    request_id: request.request_id,
    status: request.status,
    service: request.service,
    order_details: {
      building_features: getBuildingFeature(request.building_features),
      building_type: request.building_type,
      panel_size: request.panel_size ? JSON.parse(request.panel_size) : null,
    },
    times: {
      created_at: formatDate(request.created_at),
      updated_at: formatDate(request.updated_at),
      tminus: request.status === "Pending" ? getTMinus(request.created_at) : null,
    },
    coordinates: {
      lat: request.lat,
      lng: request.lon,
    },
    resolved_addr: request.resolved_addr,
    domain: {
      domain_id: request.domain_id,
      domain_name: request.domain.domain_name,
    },
    user: {
      id: request.user.id,
      email: request.user.email,
      last_name: request.user.last_name,
      first_name: request.user.first_name,
    },
    comment: request.comment,
    model_json: request.model_json,
    pcd_file: null
  }

  console.log(StoreScopedRequest.request);
};

// Set scoped request PCD file
export const SetScopedRequestPCD = (blob) => {
  StoreScopedRequest.request.pcd_file = blob;
};

// Clear scoped request
export const ClearScopedRequest = () => {
  StoreScopedRequest.request = requestInit;
};

// Set scoped request status
export const setScopedRequestStatus = (status) => {
  StoreScopedRequest.request.status = status
    ? "Available"
    : StoreScopedRequest.request.status_initial;
};

// Set expanded
export const setFullscreen = () => {
  StoreScopedRequest.fullscreen = !StoreScopedRequest.fullscreen;
};

export default StoreScopedRequest;
