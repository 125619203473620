// Navigation import
import { NavLink } from "react-router-dom";
// Components import
import Button from "../../components/Button/Button";
import Input from "../../components/Form/Input";
import Icons from "../../components/Icons/Icons";
import { useToastContext } from "../../components/Toast/Toast";
// State imports from Store
import { useSnapshot } from "valtio";
import StoreLogin, { SetUserForgottenEmail } from "../../data/store/Login";
// API import
import ForgottenPass from "../../api/forgottenpass/forgottenpass";

const ForgottenPasswordPage = () => {
  const snap = useSnapshot(StoreLogin);
  const { forgottenEmail: email} = snap;
  const addToast = useToastContext();

  // Forgotten password event handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create fetch body
    let body = {
      forgottenEmail: email,
    };
    // Call Login API
    ForgottenPass(body, addToast);
  };
  return (
    <div className="forgotten-password-page">
      <div className="forgotten-password-page__container">
        <Icons.Logo />
        <h4>Elfelejtett jelszó</h4>
        <div className="forgotten-password-page__container-action">
          <form onSubmit={handleSubmit}>
            <Input
              label="Email-cím"
              type="email"
              value={email}
              onChange={(e) => SetUserForgottenEmail(e.target.value)}
              name="email"
              required={true}
            />
            <Button actionType="submit">Küldés</Button>
            <div className="forgotten-password-page__container-action__bottom">
              <NavLink to="/">
                <Button asLink={"true"}>Mégsem</Button>
              </NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgottenPasswordPage;
