import BadgePoint from "../Badge/BadgePoint";

const CardNeedSmall = ({ id, label, status, onClick }) => {
  return (
    <div className="card__need-small" onClick={onClick}>
      <div className="card__need-small__meta">
        <h5>
          SVP-{id}
        </h5>
        <span>{label}</span>
      </div>
      <BadgePoint status={status} />
    </div>
  );
};

export default CardNeedSmall;
