import React from "react"
import Navbar from "./Navbar"
// Valtio and store imports
import { useSnapshot } from "valtio";
import SnapScopedRequest  from "../../data/store/ScopedRequest";

const Header = () => {
  const {contetExpanded} = useSnapshot(SnapScopedRequest)

  return (
    <header style={{zIndex: contetExpanded ? 1 : 2}}>
        <Navbar />
    </header>
  )
}

export default Header
