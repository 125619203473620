// React imports
import { useRef, useEffect } from "react";

// Three
import * as THREE from "three";
import { Bounds, useBounds, Billboard, Html } from "@react-three/drei";

// Use Gesture imports
import { useHover } from "@use-gesture/react";

// StoreModel and Valtio imports
import { useSnapshot } from "valtio";
import StoreModel, {
  handlePointerClick,
  handleCursorPanelClose,
} from "./StoreModel";

// Utils
import { round, weightedAverage, getCenterPoint, getAvarageCenterPoint, getBearingCardinal } from "./utils";

// Component imports
import Icons from "../Icons/Icons";


// CURSOR TOOL
const CursorTool = () => {
  return (
    <mesh>
      <Highlight/>
      <Selection/>
    </mesh>
  )
};

const Highlight = () => {
  const { currentTool } = useSnapshot(StoreModel.tool);
  const { visible, poiUID, poiType, panelHovered } = useSnapshot(StoreModel.pointer);
  const { model } = useSnapshot(StoreModel);
  const { ready } = useSnapshot(StoreModel.model);

  let geometry = new THREE.BufferGeometry();
  let shiftPosition = new THREE.Vector3();
  let centerPoint = new THREE.Vector3();

  if (currentTool === 0 && visible && ready && poiUID !== null && !panelHovered ) {

    const active = model[poiType].filter(e => e.uID === poiUID)[0];

    if (active) {
      if (poiType !== "edges") {
        // Create geometry
        let pArr = [];
        active.vertices.forEach((v) => {
          pArr.push(v.x, v.y, v.z);
        });
        const positions = new Float32Array(pArr);
        geometry.setAttribute("position", new THREE.BufferAttribute( positions, 3 ));
        geometry.computeVertexNormals();
        geometry.computeBoundingBox();
        // Shift geometry to eliminate z-fighting
        const shiftDistance = 0.01;
        const shiftNormal = active.normal;
        shiftPosition.addScaledVector(shiftNormal, shiftDistance);
        // Calculate center
        centerPoint = getCenterPoint(geometry);
      } else {
        // Create geometry
        const path = new THREE.CatmullRomCurve3(active.vertices);
        geometry = new THREE.TubeGeometry( path, 6, 0.1, 24, false );
        geometry.computeBoundingBox();
        // Calculate center
        centerPoint = getCenterPoint(geometry);
      }
    }

    return (
      <>
        <mesh
          geometry={geometry}
          position={shiftPosition}
          onClick={(e) => {
            e.stopPropagation();
            handlePointerClick( geometry, shiftPosition, centerPoint )
          }}
        >
          <meshBasicMaterial side={THREE.DoubleSide} transparent opacity={poiType !== "edges" ? 0.3 : 0.8} color={"#00FF7F"} />
        </mesh>
      </>
    )
  }
};

const Selection = () => {
  const { selection } = useSnapshot(StoreModel.cursor);

  if (selection.visible && selection.items.length > 0) {
    return(
      <>
        <Bounds fit damping={4} margin={1.5}>
          <SelectedItems/>
        </Bounds>
        <SelectedPanel center={getAvarageCenterPoint(selection.items)} />
      </>
    )
  }
};

function SelectedItems() {
  const { selection, selectionChangeTrack } = useSnapshot(StoreModel.cursor);

  const bounds = useBounds();

  useEffect(() => {
    bounds.refresh().fit();
  }, [selectionChangeTrack, bounds]);
  
  const geometries = selection.items.map((item, i) => {
    return (
      <mesh key={i} geometry={item.g} position={item.sP}>
        <meshBasicMaterial transparent opacity={0.6} attach="material" color={"#00FF7F"} />
      </mesh>
    )
  });

  return (
    <mesh>
      {geometries}
    </mesh>
  )
};

const SelectedPanel = ({ center }) => {
  const { selection } = useSnapshot(StoreModel.cursor);
  const { model } = useSnapshot(StoreModel);

  const panelRef = useRef();
  const multiSelection = selection.items.length >= 2;

  const items = selection.items.map((item) => {
    return model[item.type].filter(e => e.uID === item.uID)[0];
  });

  // Area (SUM)
  let totalArea = null;
  items.forEach((item) => {
    totalArea += item.properties.area_3d;
  });
  totalArea = round(totalArea, 2);

  // Tilt Angle (AVG)
  let tiltWeights = [];
  let tiltAngles = [];
  items.forEach((item) => {
    tiltWeights.push(item.properties.area_3d);
    tiltAngles.push(item.properties.slope);
  });
  let avgTiltAngle = weightedAverage(tiltWeights, tiltAngles);
  avgTiltAngle = isNaN(avgTiltAngle) ? null : round(avgTiltAngle, 2);

  // Bearing (AVG)
  let bearingWeights = [];
  let bearingAngles = [];
  items.forEach((item) => {
    bearingWeights.push(item.properties.area_3d);
    bearingAngles.push(item.properties.aspect);
  });
  let avgBearingAngle = round(weightedAverage(bearingWeights, bearingAngles), 0);

  // Edge Length
  let edgeLength = 0;
  items.forEach((item) => {
    edgeLength = edgeLength + item.properties.length_3d;
  });
  edgeLength = round(edgeLength, 2);

  // Solar panel width
  let solarWidth = 0;
  items.forEach((item) => {
    solarWidth = solarWidth + item.properties.width;
  });
  solarWidth = round(solarWidth/items.length, 2);

  // Solar panel height
  let solarHeight = 0;
  items.forEach((item) => {
    solarHeight = solarHeight + item.properties.height;
  });
  solarHeight = round(solarHeight/items.length, 2);

  // Hover hook
  const bind = useHover(({ active }) => {
    StoreModel.pointer.panelHovered = active;
  }, {keys: false});

  // Title
  const RenderTitle = () => {
    if (multiSelection) {
      return <h4>{selection.items.length} elem</h4>
    } else {
      return (
        <h4>{items[0].properties.LAYER} #{items[0].tID}</h4>
      )
    }
  };

  // Meta
  const RenderMeta = ({multiSelection}) => {
  
    return(
      <ul className="meta">
        {!isNaN(totalArea) && totalArea !== "0.00" &&
          <RenderParameter
            label="Terület"
            value={totalArea}
            metric=" m²"
            multiLabel="+"
            multiLabelTitle="Kijelölt síkok összege"
          />
        }
        {!isNaN(avgTiltAngle) && !multiSelection &&
          <RenderParameter
            label="Dőlésszög"
            value={avgTiltAngle}
            metric="°"
          />
        }
        {!isNaN(avgBearingAngle) && !multiSelection &&
          <RenderParameter
            label="Tájolás"
            value={getBearingCardinal(avgBearingAngle) + " / " + avgBearingAngle}
            metric="°"
          />
        }
        {!isNaN(edgeLength) &&
          <RenderParameter
            label="Hossz"
            value={edgeLength}
            metric=" m"
            multiLabel="+"
            multiLabelTitle="Kijelölt élek hossza"
          />
        }
        {!isNaN(solarWidth) &&
          <RenderParameter
            label="Panel szélesség"
            value={solarWidth}
            metric=" m"
            multiLabel="μ"
            multiLabelTitle="Kijelölt síkok átlaga"
          />
        }
        {!isNaN(solarHeight) &&
          <RenderParameter
            label="Panel hosszúság"
            value={solarHeight}
            metric=" m"
            multiLabel="μ"
            multiLabelTitle="Kijelölt síkok átlaga"
          />
        }
      </ul>
    )
  };

  // Meta Parameter
  const RenderParameter = ({ label, value, metric, multiLabel, multiLabelTitle }) => {
    if (value !== null) {
      return(
        <li>
          <span className="label">{label}</span>
          <span className="value">
            {value}{metric}
            <div
              className={`multi-label ${multiSelection ? "active" : ""}`}
              title={multiLabelTitle}
            >
              {multiLabel}
            </div>
          </span>
        </li>
      )
    }
  };

  const handleCursorPanelCopy = () => {
    const copyData = [totalArea, avgTiltAngle, avgBearingAngle, edgeLength, solarWidth, solarHeight];
    let copyValues = [];

    copyData.forEach((data) => {
      !isNaN(data) && copyValues.push(data);
    });
    navigator.clipboard.writeText(copyValues.join("\n"));
  };

  return(
    <Billboard
      ref={panelRef}
      follow={true}
      position={center}
    >
      <Html className="cursor-panel" center position={[0, 0, 0.5]} >
        <div className="wrapper" {...bind()} >
          <div className="head">
            <RenderTitle />
            <button
              className="close"
              onClick={(e) => {
                e.stopPropagation();
                handleCursorPanelClose()
              }}
            >
              <Icons.Close/>
            </button>
          </div>
          <RenderMeta
            multiSelection={multiSelection}
          />
          <button
            className="copy"
            onClick={(e) => {
              e.stopPropagation();
              handleCursorPanelCopy();
            }}
          >
            <Icons.Copy/>
            <span>Adatok másolása</span>
          </button>
        </div>
      </Html>
    </Billboard>
  )
};

export default CursorTool;