//Imports
import { SetScopedUserActive } from "../../data/store/ScopedUser";
import { urls } from "../urls";
import { CheckError } from "../../utils/api/checkError";

// Get admin information
const editScopedUserActive = async (body, message) => {
  // Check users status. If active, patch will deacitvate user
  // if inactive, patch will activate user 
  let requestUrl = urls.userByID + body.userID;
  if(body.isActive) requestUrl = requestUrl + "/deactivate";
  if(!body.isActive) requestUrl = requestUrl + "/active";

  await fetch(requestUrl, {
    method: "PATCH",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      SetScopedUserActive(!body.isActive)
      if(body.isActive) message(["success", "Változtatások mentése sikeres. Felhasználó inaktív."]);
      if(!body.isActive) message(["success", "Változtatások mentése sikeres. Felhasználó aktív."]);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default editScopedUserActive;
