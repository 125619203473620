// Imports
import { urls } from "../urls";
import { CheckError } from "../../utils/api/checkError";
import { SetScopedRequest } from "../../data/store/ScopedRequest";

// Linked API
import getPCDFile from "./getPCDFile";

// Get scoped request
const getScopedRequest = async (body, message) => {
  await fetch(urls.requestByID + body.id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      // Set Store
      SetScopedRequest(actualData);
      // Try LiDAR PCD file
      if (actualData.model_json !== null) getPCDFile(body, message);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getScopedRequest;
