// Component imports
import Textarea from "../../Form/Textarea";

const ModalRequestDenied = ({
  handleInput,
  formData
}) => {

  return (
    <div className="modal__form__fields">
      <Textarea
        label="Szöveges magyarázat"
        value={formData.comment}
        onChange={handleInput}
        name="comment"
      />
    </div>
  );
};

export default ModalRequestDenied;
