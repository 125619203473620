// Imports
import { urls } from "../urls";
import { SetRequestsList } from "../../data/store/RequestsList";
import { CheckError } from "../../utils/api/checkError";
import { SetScopedDomainPrevReqs } from "../../data/store/ScopedDomain";

// Get users list
const getRequests = async (body, message) => {
  await fetch(urls.requests, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    }
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      SetScopedDomainPrevReqs(actualData);
      SetRequestsList(actualData)
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getRequests;
