// Imports
import { urls } from "../urls";
import {
  SetUserLoggedIn,
  SetUserEmail,
} from "../../data/store/Login";
// Cookie import
import Cookies from "universal-cookie";
// Error import
import { CheckError } from "../../utils/api/checkError";

// Login
const Login = async (body, message) => {
  await fetch(urls.login, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: body.email,
      password: body.password,
    }),
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {

      // Save state to localstorage
      localStorage.setItem("userLoggedIn", true);

      // Store user token
      localStorage.setItem("userToken", actualData.token);

      // If user checked remember me save this state to localstorage
      if (body.rememberMe) {
        const cookies = new Cookies();
        cookies.set("email", body.email, { path: "/" });
        cookies.set("password", body.password, { path: "/" });
      }

      // Save user email
      SetUserEmail(body.email);

      // Redirect user
      SetUserLoggedIn(true);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default Login;
