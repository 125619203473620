// React imports
import { useEffect, useState, useContext } from "react";
// Component imports
import Button from "../../Button/Button";
import Input from "../../Form/Input";
import Modal from "../Modal";
import Users from "../../Users/Users";
import InputDouble from "../../Form/InputDouble";
import ModalLVStats from "../Partials/ModalLVStats";
import Select from "../../Form/Select";
// State management imports
import { subscribe, snapshot } from "valtio";
// Store and API import
import StoreScopedDomain from "../../../data/store/ScopedDomain";
import editScopedDomain from "../../../api/domain/editScopedDomain";
import getScopedRequest from "../../../api/request/getScopedRequest";
// Toast import
import { useToastContext } from "../../Toast/Toast";
// Modal import
import { ModalContext } from "../../Router/Router";
import CardNeedSmall from "../../Cards/CardNeedSmall";
import EmptyList from "../../Empty/EmptyList";
import deleteScopedDomain from "../../../api/domain/deleteScopedDomain";

const ModalDomain = ({ isModalOpen }) => {
  const [frameData, setFrameData] = useState({});
  const [formData, setFormData] = useState({});
  const { modalOpen, modalType } = useContext(ModalContext);
  const addToast = useToastContext();
  const snapPrevReqs = snapshot(StoreScopedDomain.domain_requests);

  useEffect(() => {
    // const addToast = useToastContext();
    // Subscribe to Store
    subscribe(StoreScopedDomain, () => {
      const snap = snapshot(StoreScopedDomain.domain);
      if (snap.id) {
        setFrameData({
          id: snap.id,
          domain_name: snap.domain,
          requests_length: snap.requests_length,
          created_at: snap.created_at,
          users_length: StoreScopedDomain.users.length,
        });
        setFormData({
          id: snap.id,
          domainID: snap.domain_id,
          domain: snap.domain,
          company_name: snap.company_name,
          company_postal: snap.company_postal,
          company_city: snap.company_city,
          company_address: snap.company_address,
          company_vat: snap.company_vat,
          package: snap.package,
        });
      }
    });
  }, []);

  // Handle input change
  const handleInput = (e) => {
    setFormData((prevFormData) => {
      const { name, value, type, checked } = e.target;
      if (type === "select-one" && name === "domain_package") {
        return {
          ...prevFormData,
          package: value,
        };
      } else {
        return {
          ...prevFormData,
          [name]: type === "checkbox" ? checked : value,
        };
      }
    });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create request body
    let body = {
      id: frameData.id,
      token: localStorage.getItem("userToken"),
      domain_id: formData.domainID,
      domain_name: formData.domain,
      company_name: formData.company_name,
      company_postal: formData.company_postal,
      company_city: formData.company_city,
      company_address: formData.company_address,
      company_vat: formData.company_vat,
      package: formData.package,
      updated_at: Date().toLocaleString(),
    };

    // Call edit domain endpoint
    editScopedDomain(body, addToast, modalOpen);
  };

  // Open modal with clicked request
  const openModalClickedRequest = (request_id) => {
    let body = {
      token: localStorage.getItem("userToken"),
      id: request_id,
    };
    getScopedRequest(body, addToast);
    modalType("requests");
  };

  // Utility for value
  const utilityCheckValue = (value) => (value === undefined ? "" : value);

  // Delete domain
  const handleDeleteDomain = () => {
    addToast([
      // Style of toast
      "error",
      // Short text of toast
      "Biztos törölni kívánja a domaint?",
      // Type of toast
      "action",
      // Long text of toast
      "A törlése viszavonhatatlan művelet, a domainhez tartozó adatok nem visszaállíthathatóak.",
      // Action of toast
      deleteScopedDomainPassed,
      // Action button of toast
      "Törlés megerősítése",
    ]);
  }

    // Handle delete domain function
    const deleteScopedDomainPassed = () => {
      let body = {
        id: formData.domainID,
        token: localStorage.getItem("userToken"),
      };
      deleteScopedDomain(body, addToast, modalOpen);
    };


  return (
    <Modal
      isModalOpen={isModalOpen}
      type="domain"
      title={frameData.domain_name}
      fluid
    >
      <main>
        <form className="modal__form" onSubmit={(e) => handleSubmit(e)}>
          <div className="modal__form__row">
            <div className="modal__form__fields">
              <div className="modal__form__fields-group">
                <h4>Domain beállítások</h4>
                <Input
                  label="Domain"
                  type="text"
                  value={utilityCheckValue(formData.domain)}
                  onChange={handleInput}
                  name="domain"
                />
                <Input
                  label="Cég"
                  type="text"
                  value={utilityCheckValue(formData.company_name)}
                  onChange={handleInput}
                  name="company_name"
                />
                <Input
                  label="Adószám"
                  type="text"
                  value={utilityCheckValue(formData.company_vat)}
                  onChange={handleInput}
                  name="company_vat"
                />
                <InputDouble
                  labelLeft="IRSZ"
                  labelRight="Város"
                  typeLeft="text"
                  typeRight="text"
                  valueLeft={utilityCheckValue(formData.company_postal)}
                  valueRight={utilityCheckValue(formData.company_city)}
                  onChangeLeft={handleInput}
                  onChangeRight={handleInput}
                  nameLeft="company_postal"
                  nameRight="company_city"
                />
                <Input
                  label="Cím"
                  type="text"
                  value={utilityCheckValue(formData.company_address)}
                  onChange={handleInput}
                  name="company_address"
                />
                <Select
                  label="Csomag"
                  options={[
                    { value: true, label: "Demo" },
                    { value: false, label: "Korlátlan" },
                  ]}
                  onChange={handleInput}
                  name="domain_package"
                  selected={formData.package}
                  value={utilityCheckValue(formData.package)}
                />
              </div>
            </div>
            <div className="modal__form__fields">
              <div className="modal__form__fields-group">
                <h4>Felhasználók</h4>
                {frameData.users_length > 0 ? (
                  <Users usersList={StoreScopedDomain.users} />
                ) : (
                  <EmptyList
                    headline="Nincs felhasználó"
                    message="Ehhez a domainhez nem tartozik felhasználó."
                  />
                )}
              </div>
            </div>
          </div>

          <div className="modal__actions">
            <Button asLink={true} onClickEvent={() => handleDeleteDomain()}>Domain törlése</Button>
            <Button actionType="submit">Mentés</Button>
          </div>
        </form>
      </main>
      <hr />
      <aside>
        <ModalLVStats
          requests={frameData.requests_length}
          date={frameData.created_at}
          type="domain"
        />
        <div className="modal__block">
          <h4>Legutóbbi igények</h4>
          <div className="modal__list-requests">
            {snapPrevReqs.length > 0 &&
              snapPrevReqs.map((element, index) => {
                return (
                  <CardNeedSmall
                    key={index}
                    id={element.request_id}
                    label={element.resolved_addr}
                    onClick={() => openModalClickedRequest(element.request_id)}
                    status={element.status}
                  />
                );
              })}
            {snapPrevReqs.length === 0 && (
              <EmptyList
                headline="Nincs kérés"
                message="Ehhez a domainhez nem tartozik még kérés."
              />
            )}
          </div>
        </div>
      </aside>
    </Modal>
  );
};

export default ModalDomain;
