// Imports
import { urls } from "../urls";
import { CheckError } from "../../utils/api/checkError";
import { setScopedRequestStatus } from "../../data/store/ScopedRequest";
import getScopedRequest from "./getScopedRequest";

// Edit scoped request
const editScopedRequest = async (body, message) => {
  await fetch(urls.requests + body.id, {
    method: "PATCH",
    mode: "cors",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      status: body.status,
      comment: body.comment,
    }),
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      setScopedRequestStatus(body.status);
      // Set message is denied is successfully 
      if(body.status === "Denied") message(["success", "Sikeres elutasítás!"])
      // Refresh scoped user
      getScopedRequest(body, message);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default editScopedRequest;
