// OL Tile
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
// OL Vector
import VectorLayer from "ol/layer/Vector";
import VectorSource from 'ol/source/Vector';
import { Icon, Style } from "ol/style";
// Pin images
import PinSel from "../../assets/images/Pins/Pin.svg";
// GeoJSON and Style
import { transformExtent } from "ol/proj";



// OSM BACKGROUND LAYER
export const backgroundLayer = new TileLayer({
  source: new XYZ({
    url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    maxZoom: 19,
  }),
  name: "backgroundLayer",
  className: 'bw-layer',
  opacity: 0.6
});


// GM SATALITE LAYER
export const satalaiteLayer = new TileLayer({
  source: new XYZ({
    url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
  }),
  name: "satalaiteLayer",
});


// ORTO LAYERS
export const ortoLayer = new TileLayer({
  source: new XYZ({
    url: "https://tileserver{1-8}.envimap.hu/Full_Merge_230404/{z}/{x}/{y}",
    crossOrigin: "Anonymous",
    minZoom: 8,
    maxZoom: 18,
  }),
  name: "topoOrtoLayer",
  extent: transformExtent(
    [16.380969,46.050858,22.970843,48.436549],
    'EPSG:4326','EPSG:3857'
  ),
});

export const modelortoLayer = new TileLayer({
  source: new XYZ({
    url: "https://tileserver{1-8}.envimap.hu/Full_Merge_230404/{z}/{x}/{y}",
    crossOrigin: 'anonymous',
    minZoom: 18,
    maxZoom: 18,
  }),
  name: "modelortoLayer",
});

/*
export const budapestOrtoLayer = new TileLayer({
  source: new XYZ({
    url: "https://tileserver{1-8}.envimap.hu/2021_BP_Agglo_MAX_8_19/2021_BP_Agglo_MAX_8_19/{z}/{x}/{y}",
    crossOrigin: 'anonymous',
    minZoom: 8,
    maxZoom: 19,
  }),
  name: "budapestOrtoLayer",
  extent: transformExtent(
    [16.482777,46.761722,21.851695,48.157449],
    'EPSG:4326','EPSG:3857'
  ),
});

export const citiesOrtoLayer = new TileLayer({
  source: new XYZ({
    url: "https://tileserver{1-8}.envimap.hu/varosok_merge_8_20/varosok_merge_8_20/{z}/{x}/{y}",
    crossOrigin: 'anonymous',
    minZoom: 8,
    maxZoom: 20,
  }),
  name: "citiesOrtoLayer",
  extent: transformExtent(
    [16.482777,46.761722,21.851695,48.157449],
    'EPSG:4326','EPSG:3857'
  ),
});

export const topoOrtoLayer = new TileLayer({
  source: new XYZ({
    url: "https://tileserver{1-8}.envimap.hu/Topo_merge_8_18/Topo_merge_8_18/{z}/{x}/{y}",
    crossOrigin: 'anonymous',
    minZoom: 8,
    maxZoom: 18,
  }),
  name: "topoOrtoLayer",
  extent: transformExtent(
    [16.482777,46.761722,21.851695,48.157449],
    'EPSG:4326','EPSG:3857'
  ),
});
*/

// PIN LAYER
// icon style
const iconStyle = new Style({
  image: new Icon({
    src: PinSel,
    anchor: [0.5, 1],
    anchorXUnits: "fraction",
    anchorYUnits: "fraction",
  }),
});
export const pinLayer = new VectorLayer({
  source: new VectorSource(),
  style: iconStyle,
  name: "pinLayer"
});