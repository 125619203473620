// React imports
import { useEffect, useState, useContext } from "react";
// Component imports
import Button from "../../Button/Button";
import Input from "../../Form/Input";
import Icons from "../../Icons/Icons";
import Modal from "../Modal";
// Toast import
import { useToastContext } from "../../Toast/Toast";
// State imports from Store
import { useSnapshot } from "valtio";
import SnapNewPass, {
  SetOldPass,
  SetNewPass,
} from "../../../data/store/NewPass";
import newPass from "../../../api/admin/editAdminPass";
import getAdmin from "../../../api/admin/getAdmin";
import SnapAdminData, {
  SetAdminName,
} from "../../../data/store/AdminData";
// Modal import 
import { ModalContext } from "../../Router/Router";


const ModalProfile = ({ isModalOpen }) => {
  const { oldPassword, newPassword } = useSnapshot(SnapNewPass);
  const { adminName } = useSnapshot(SnapAdminData);
  const { modalOpen } = useContext(ModalContext);
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewtPass, setShowNewtPass] = useState(false);
  const addToast = useToastContext();

  const formHandler = (e) => {
    e.preventDefault();
    // Create request body
    let body = {
      old_password: oldPassword,
      new_password: newPassword,
      token: localStorage.getItem("userToken"),
    };
    // Call new password generate endpoint
    newPass(body, addToast, SetNewPass, SetOldPass, modalOpen);
  };

  useEffect(() => {
    // Create request body
    let body = {
      token: localStorage.getItem("userToken"),
    };
    // Call get admin data endpoint
    getAdmin(body, addToast);
  }, [addToast]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      title={"Fiók"}
      hasFooter={true}
      pagination={false}
    >
      <form onSubmit={formHandler} className="modal__form-fixed">
        <h4>Személyes adatok</h4>
        <div className="modal__content-input">
          <Input
            value={adminName}
            label="Név"
            type="text"
            name="username"
            onChange={(e) => SetAdminName(e.target.value)}
          />
        </div>
        <hr />
        <h4>Jelszó módosítása</h4>
        <div className="modal__content-input">
          <label>
            Aktuális jelszó
            <div className="modal__content-input-container">
              <input
                value={oldPassword}
                onChange={(e) => SetOldPass(e.target.value)}
                id="currentPass"
                type={showCurrentPass ? "text" : "password"}
                name="currentPass"
                autoComplete="on"
              />
              <span
                onMouseDown={() => setShowCurrentPass(true)}
                onMouseUp={() => setShowCurrentPass(false)}
              >
                <Icons.ViewHide className={showCurrentPass ? "d-none" : ""} />
                <Icons.ViewShow className={showCurrentPass ? "" : "d-none"} />
              </span>
            </div>
          </label>
        </div>
        <div className="modal__content-input">
          <label>
            Új jelszó
            <div className="modal__content-input-container">
              <input
                value={newPassword}
                onChange={(e) => SetNewPass(e.target.value)}
                id="newPass"
                type={showNewtPass ? "text" : "password"}
                name="newPass"
                autoComplete="on"
              />
              <span
                onMouseDown={() => setShowNewtPass(true)}
                onMouseUp={() => setShowNewtPass(false)}
              >
                <Icons.ViewHide className={showNewtPass ? "d-none" : ""} />
                <Icons.ViewShow className={showNewtPass ? "" : "d-none"} />
              </span>
            </div>
          </label>
        </div>
        <div className="modal__form-fixed-actions">
          <Button buttonType="neutral">Mégse</Button>
          <Button actionType="submit">Mentés</Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalProfile;
