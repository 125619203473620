const InputDouble = ({
  labelLeft,
  labelRight,
  typeLeft = "text",
  typeRight = "text",
  valueLeft,
  valueRight,
  onChangeLeft,
  onChangeRight,
  nameLeft,
  nameRight,
}) => {
  return (
    <div className="input double">
      <label className="input double-left">
        {labelLeft}
        <input
          type={typeLeft}
          name={nameLeft}
          value={valueLeft}
          onChange={onChangeLeft}
        />
      </label>
      <label className="input double-right">
        {labelRight}
        <input
          type={typeRight}
          name={nameRight}
          value={valueRight}
          onChange={onChangeRight}
        />
      </label>
    </div>
  );
};

export default InputDouble;
