import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createContext, useState, useEffect } from "react";
import LoginPage from "../../pages/login/Login";
import ForgottenPasswordPage from "../../pages/forgotten/Forgotten";
import Dashboard from "../../pages/dashboard/Dashboard";
import RequestsPage from "../../pages/requests/Requests";
import UsersPage from "../../pages/users/Users";
import Error from "../../pages/error/Error";
import Container from "../Layout/Container";
import ModalManger from "../Modal/ModalManager";
import { useSnapshot } from "valtio";
import StoreLogin, { SetUserLoggedIn } from "../../data/store/Login";
import { ToastContextProvider } from "../Toast/Toast";
import DomainsPage from "../../pages/domains/Domains";

export const ModalContext = createContext();

const MainRouter = ({ children }) => {
  const { loggedIn } = useSnapshot(StoreLogin);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("registration");

  useEffect(() => {
    isModalOpen ? OpenModalFunction() : (document.body.style.overflow = "");
    return () => {};
  }, [isModalOpen]);

  const OpenModalFunction = () => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (localStorage.getItem("userLoggedIn")) SetUserLoggedIn(true);
  }, [loggedIn]);

  return loggedIn ? (
    <ToastContextProvider>
      <ModalContext.Provider
        value={{ modalOpen: setIsModalOpen, modalType: setModalType }}
      >
        <ModalManger
          type={modalType}
          isModalOpen={isModalOpen}
          setIsOpen={isModalOpen}
        />
        <Router>
          {children}
          <Container>
            <Routes>
              <Route path="/">
                <Route index element={<Dashboard />} />
                <Route path="requests" element={<RequestsPage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="domains" element={<DomainsPage />} />
                <Route path="*" element={<Error />} />
              </Route>
            </Routes>
          </Container>
        </Router>
      </ModalContext.Provider>
    </ToastContextProvider>
  ) : (
    <ToastContextProvider>
      <Router>
        <Routes>
          <Route path="/">
            <Route index element={<LoginPage />} />
            <Route path="forgotten" element={<ForgottenPasswordPage />} />
            <Route path="*" element={<LoginPage />} />
          </Route>
        </Routes>
      </Router>
    </ToastContextProvider>
  );
};

export default MainRouter;
