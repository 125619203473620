import Header from "./components/Layout/Header";
import MainRouter from "./components/Router/Router";

const App = () => {
  return (
    <MainRouter>
      <Header />
    </MainRouter>
  );
};

export default App;
