// React imports
import { useContext } from "react";
// Component imports
import Icons from "../Icons/Icons";
// Toast import
import { useToastContext } from "../Toast/Toast";
// Router
import { ModalContext } from "../Router/Router";
// State and API imports
import { snapshot } from "valtio";
import StoreScopedRequest from "../../data/store/ScopedRequest";
// API
import getScopedUser from "../../api/user/getScopedUser";
import getDomains from "../../api/domain/getDomains";
import getScopedDomain from "../../api/domain/getScopedDomain";

// Proj4
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { transform } from "ol/proj";

// EPSG:23700 used by the availabilityLayer JSON data
proj4.defs('EPSG:23700',
  '+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs');
register(proj4);


const CardModal = ({ type, data }) => {
  const { coordinates, domain, user } = snapshot(StoreScopedRequest.request);
  const { modalType } = useContext(ModalContext);
  const addToast = useToastContext();

  // Convert coordinates to EOV cooords
  const coordinatesEOV = transform(
    [coordinates.lng, coordinates.lat],
    "EPSG:4326",
    "EPSG:23700"
  );

  // Copy-paste coordinates 
  const handleCopyLocation = () => {
    // Get coordinates and format text
    let copiedText = `${coordinatesEOV[0]}, ${coordinatesEOV[1]}`;
    // Copy the text 
    navigator.clipboard.writeText(copiedText);
    // Show message after copied 
    addToast(["success", "Koordináták másolva!"]);
  };

  // Open model with scoped user 
  const openModalWithContent = () => {
    let body = {
      token: localStorage.getItem("userToken"),
      id: user.id,
    };
    let bodyID = {
      token: localStorage.getItem("userToken"),
      id: domain.domain_id,
    };
    getScopedUser(body, addToast)
    getDomains(body, addToast)
    getScopedDomain(bodyID, addToast)
    modalType("users");
  };

  return (
    <div className="card__modal">
      {type === "date" && (
        <>
          <div className="card__modal-header">
            <span className="span--bold">Dátum</span>
          </div>
          <div className="card__modal-meta">
            <span>Beküldve: {data.created_at}</span>
            {data.tminus ? (
              <span className="span--bold">
                {`${Math.abs(data.tminus.hours)}óra ${Math.abs(data.tminus.minutes)}perc `}
                {data.tminus.minutes > 0 ? "a határidőig" : "késésben"}
              </span>
            ):(
              <span>Szerkesztve: {data.updated_at}</span>
            )}
          </div>
        </>
      )}

      {type === "location" && (
        <>
          <div className="card__modal-header">
            <span className="span--bold">EOV Koordináták</span>
            <button onClick={handleCopyLocation}>
              <Icons.Copy />
            </button>
          </div>
          <div className="card__modal-meta">
            <span>{coordinatesEOV[0]}</span>
            <span>{coordinatesEOV[1]}</span>
          </div>
        </>
      )}

      {type === "request-type" && (
        <>
          <div className="card__modal-header">
            <span className="span--bold">Típus</span>
          </div>
          <div className="card__modal-meta">
            <span>{data.building_type}</span>
            <span className="span--bold">{data.building_features}</span>
            {data.panel_size && (
              <span className="span--bold boxed-text">
                {data.panel_size[0]}mm x {data.panel_size[1]}mm
              </span>
            )}
          </div>
        </>
      )}

      {type === "user" && (
        <>
          <div className="card__modal-header">
            <span className="span--bold">Igénylő</span>
            <button onClick={openModalWithContent}>
              <Icons.ArrowForward />
            </button>
          </div>
          <div className="card__modal-meta">
            <span>{data[0].last_name + " " + data[0].first_name}</span>
            <span className="span--bold">{data[1].domain_name}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default CardModal;
