// Imports
import { urls } from "../urls";
import { SetScopedUserPrevReqs } from "../../data/store/ScopedUser";
import { CheckError } from "../../utils/api/checkError";

// Get selected user prvious requests
const getScopedUserPrevReqs = async (body, message) => {
  await fetch(urls.requestByDomain + body.domainID, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + body.token,
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => {
      let filteredList = actualData.filter(
        (element) => element.user.id === body.userID
      );
      SetScopedUserPrevReqs(filteredList);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getScopedUserPrevReqs;
