// Component imports
import Icons from "../Icons/Icons";
import Row from "../Layout/Row";

const CarouselSmall = ({ prevStatus, prevUser, nextStatus, nextUser }) => {
  return (
    <div className="carousel small">
      <Row justify={["xsSpaceBetween"]}>
        <div className={`carousel__icon left ${!prevStatus && "inactive"}`}>
          <button
            disabled={prevStatus ? false : true}
            onClick={() => prevUser()}
          >
            <Icons.ArrowLeft color="white" />
          </button>
        </div>
        <div className={`carousel__icon right ${!nextStatus && "inactive"}`}>
          <button
            disabled={nextStatus ? false : true}
            onClick={() => nextUser()}
          >
            <Icons.ArrowRight color="white" />
          </button>
        </div>
      </Row>
    </div>
  );
};

export default CarouselSmall;
